import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetPatientDocumentDetailsMutation } from "../../services/apiService";
import { setPatientDocumentsDetail } from "../../redux/slice/patientSlice";
import AdminLayout from "../Admin/AdminLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { IconButton, Box, Modal, Typography, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinkIcon from "@mui/icons-material/Link";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "../elements/Spinner/Spinner";
import UserLayout from "../User/UserLayout";
import CryptoJS from "crypto-js";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";

const DocumentModal = ({ open, onClose, rtfContent }) => (
  <Modal open={open} onClose={onClose} sx={{ zIndex: 555 }}>
    <Box
      sx={{
        position: "absolute",
        top: "52%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: "auto",
        maxHeight: "80vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Document Details</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ marginTop: "16px" }}>
        <div dangerouslySetInnerHTML={{ __html: rtfContent }} />
      </div>
    </Box>
  </Modal>
);

const PatientDocumentDetails = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [rtfContent, setRtfContent] = useState("");
  const dispatch = useDispatch();
  const selectedPatient = localStorage.getItem("patient");
  const patient = selectedPatient && JSON.parse(selectedPatient);
  const encryptedUser = localStorage.getItem("user_details");
  const isSearchBarOpen = useSelector((state) => state.auth.isSearchBarOpen);
  const [delayedMaxHeight, setDelayedMaxHeight] = useState(
    "calc(100vh - 238px)"
  );

  useEffect(() => {
    if (isSearchBarOpen) {
      setDelayedMaxHeight("calc(100vh - 296px)");
    } else {
      const timer = setTimeout(() => {
        setDelayedMaxHeight("calc(100vh - 238px)");
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isSearchBarOpen]);

  const userDetails =
    encryptedUser &&
    CryptoJS.AES.decrypt(encryptedUser, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);

  const userDetailsData = userDetails && JSON.parse(userDetails);

  const Layout = userDetailsData?.IsAdmin === true ? AdminLayout : UserLayout;

  const [getPatientDocumentDetails, { isLoading, isFetching }] =
    useGetPatientDocumentDetailsMutation();
  const patientDocumentDetails = useSelector(
    (state) => state.patient.patientDocumentsDetail
  );

  const fetchDocumentDetails = () => {
    const payload = {
      PatientId: patient?.patientId,
      FromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
      ToDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
    };
    getPatientDocumentDetails({ query: "", payload }).then((res) => {
      if (res?.data) {
        dispatch(setPatientDocumentsDetail(res?.data));
      }
    });
  };

  useEffect(() => {
    fetchDocumentDetails();
  }, [patient?.patientId, fromDate, toDate]);

  const handleViewButtonClick = (docData) => {
    setRtfContent(docData);
    setOpenModal(true);
  };

  const columns = useMemo(
    () =>
      patientDocumentDetails?.length > 0
        ? Object.keys(patientDocumentDetails[0]).map((key) => {
            return {
              accessorKey: key,
              header: key,
              size: 200,
              Cell: ({ renderedCellValue, row }) => {
                if (key === "Document Data") {
                  return renderedCellValue ? (
                    <IconButton
                      color="primary"
                      onClick={() => handleViewButtonClick(renderedCellValue)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  ) : (
                    ""
                  );
                } else if (key === "File Path") {
                  return renderedCellValue ? (
                    <IconButton
                      sx={{ margin: 0, padding: 0 }}
                      color="primary"
                      component="a"
                      href={renderedCellValue}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkIcon />
                    </IconButton>
                  ) : (
                    ""
                  );
                } else if (
                  [
                    "EncounterDate",
                    "DateUploaded",
                    "DocumentDate",
                    "DateOfService",
                    "OriginationTimestamp",
                    "TranscriptionTimestamp",
                    "OrderedDate",
                    "SignedOn",
                    "VersionDate",
                    "VerifiedDate",
                    "PerformedDate",
                    "BytePadCreatedDate",
                  ].includes(key)
                ) {
                  return renderedCellValue
                    ? moment(renderedCellValue).format("MM/DD/YYYY HH:mm")
                    : "";
                }
                return renderedCellValue;
              },
            };
          })
        : [],
    [patientDocumentDetails]
  );

  const table = useMaterialReactTable({
    columns,
    data: patientDocumentDetails || [],
    enableDensityToggle: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    // enableGrouping: true,
    initialState: {
      density: "compact",
      // grouping: ["Document Date"],
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: delayedMaxHeight,
        transition: "max-height 0.5s ease-in-out",
        zIndex: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #f5aa50",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        "& .Mui-TableHeadCell-Content-Labels": {
          padding: "0px",
        },
        "& .MuiBox-root": {
          padding: "0px",
        },
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
      customizeToolbar: {
        height: "50px",
      },
    },
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#e0e0e0",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          selectsStart
          startDate={fromDate}
          endDate={toDate}
          showYearDropdown
          placeholderText="From"
          popperProps={{
            style: { zIndex: "9999999 !important" },
            strategy: "fixed",
          }}
          popperPlacement="bottom-end"
          className="date-picker"
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          selectsEnd
          startDate={fromDate}
          endDate={toDate}
          minDate={fromDate}
          showYearDropdown
          placeholderText="To"
          popperProps={{
            style: { zIndex: "9999999 !important" },
            strategy: "fixed",
          }}
          popperPlacement="bottom-end"
          className="date-picker"
        />
        <IconButton onClick={fetchDocumentDetails}>
          <SearchIcon />
        </IconButton>
      </Box>
    ),
  });

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="outer-dash-section">
            <div className="patient-sec">
              <div className="">
                <div className="row">
                  <div className="col-lg-12 left-column">
                    <div className="box-shadow-set p-2">
                      <div className="configure-head">
                        <div className="same-heading-icon">
                          <i className="fa fa-file-alt fe-16" />
                          <h2>Document Details</h2>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="common-table">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                      <DocumentModal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        rtfContent={rtfContent}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner isLoading={isLoading || isFetching} />
    </Layout>
  );
};

export default PatientDocumentDetails;
