import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import ProtectedRoutes from "./components/routes/ProtectedRoutes";
import { ROLES } from "./config/roles";
import AdminPatientList from "./pages/Admin/AdminPatientList";
import PatientDemographics from "./components/PatientDemographics/PatientDemographics";
import PatientAppointments from "./components/PatientAppointments/PatientAppointments";
import PatientEncounters from "./components/PatientEncounters/PatientEncounters";
import PatientProblems from "./components/PatientProblems/PatientProblems";
import PatientAllergies from "./components/PatientAllergies/PatientAllergies";
import PatientMedications from "./components/PatientMedications/PatientMedications";
import PatientImmunizations from "./components/PatientImmunizations/PatientImmunizations";
import PastMedicalHistory from "./components/PastMedicalHistory/PastMedicalHistory";
import FamilyHistory from "./components/FamilyHistory/FamilyHistory";
import SocialHistory from "./components/SocialHistory/SocialHistory";
import PatientLabResults from "./components/LabResults/PatientLabResults";
import VitalDetails from "./components/VitalDetails/VitalDetails";
import PatientDocumentDetails from "./components/PatientDocumentDetails/PatientDocumentDetaiIs";
import UserResetPassword from "./pages/User/UserResetPassword";
import UserPatientList from "./pages/User/UserPatientList";
import AdminResetPassword from "./pages/Admin/AdminResetPassword";
import FieldConfiguration from "./components/FieldConfiguration/FieldConfiguration";
import ClientConfiguration from "./components/ClientConfiguration/ClientConfiguration";
import UserConfiguration from "./components/UserConfiguration/UserConfiguration";
import RolesConfiguration from "./components/RolesConfiguration.jsx/RolesConfiguration";
import MenuConfiguration from "./components/MenuConfiguration/MenuConfiguration";
import PatientCareTeam from "./components/PatientCareTeam/PatientCareTeam";
import PatientEncounterInsurance from "./components/PatientEncounterInsurance/PatientEncounterInsurance";
import PatientSurgicalHistory from "./components/PatientSurgicalHistory/PatientSurgicalHistory";
import PatientPharmacy from "./components/PatientPharmacy/PatientPharmacy";
import PatientEncounterProvider from "./components/PatientEncounterProvider/PatientEncounterProvider";
import PatientDiagnosis from "./components/PatientDiagnosis/PatientDiagnosis";
import PatientOrder from "./components/PatientOrder/PatientOrder";
import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "./config/config";
import { useEffect, useMemo, useState } from "react";
import Spinner from "./components/elements/Spinner/Spinner";

export const BaseRoutes = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [MenuDetails, setMenuDetails] = useState(null);

  useEffect(() => {
    const fetchMenuDetails = () => {
      const userMenuDetails = localStorage.getItem("menu_details");
      if (userMenuDetails) {
        try {
          const userAvailableMenus = CryptoJS.AES.decrypt(
            userMenuDetails,
            REACT_APP_CRYPTOJS_SECRETKEY,
            { iv: REACT_APP_CRYPTOJS_VECTOR }
          ).toString(CryptoJS.enc.Utf8);

          setMenuDetails(JSON.parse(userAvailableMenus));
        } catch (error) {
          console.error("Error decrypting or parsing menu details:", error);
          setMenuDetails(null);
        }
      }
      setIsFetching(false);
    };

    fetchMenuDetails();
  }, []);

  if (isFetching) {
    return <Spinner isLoading={isFetching} />;
  }

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />

      <Route element={<ProtectedRoutes allowedRoles={ROLES.USER} />}>
        <Route path="/user/patient-list/all" element={<UserPatientList />} />

        <Route
          path="/user/patient-list/patient/demographics"
          element={<PatientDemographics />}
        />
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Appoinments" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/appointments"
              element={<PatientAppointments />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Care Team" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/care-team"
              element={<PatientCareTeam />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Encounter" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/encounters"
              element={<PatientEncounters />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Encounter Insurance" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/encounter-insurance"
              element={<PatientEncounterInsurance />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Surgical History" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/surgical-history"
              element={<PatientSurgicalHistory />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Patient Pharmacy" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/patient-pharmacy"
              element={<PatientPharmacy />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Encounter Provider" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/encounter-provider"
              element={<PatientEncounterProvider />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Diagnosis" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/diagnosis"
              element={<PatientDiagnosis />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return menu?.MenuName === "Order" && menu?.IsGrantAccess === true;
          }) && (
            <Route
              path="/user/patient-list/patient/order"
              element={<PatientOrder />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Problems" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/problems"
              element={<PatientProblems />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Allergies" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/allergies"
              element={<PatientAllergies />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Medications" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/medications"
              element={<PatientMedications />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Immunizations" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/immunizations"
              element={<PatientImmunizations />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Past Medical History" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/past-medical-history"
              element={<PastMedicalHistory />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Family History" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/family-history"
              element={<FamilyHistory />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Social History" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/patient/social-history"
              element={<SocialHistory />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return menu?.MenuName === "Result" && menu?.IsGrantAccess === true;
          }) && (
            <Route
              path="/user/patient-list/patient/lab-results"
              element={<PatientLabResults />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return menu?.MenuName === "Vital" && menu?.IsGrantAccess === true;
          }) && (
            <Route
              path="/user/patient-list/:patientId/vital-details"
              element={<VitalDetails />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Documents" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/user/patient-list/:patientId/images"
              element={<PatientDocumentDetails />}
            />
          )}

        <Route path="/user/reset-password" element={<UserResetPassword />} />
      </Route>

      <Route element={<ProtectedRoutes allowedRoles={ROLES.ADMIN} />}>
        <Route path="/admin/patient-list/all" element={<AdminPatientList />} />
        <Route
          path="/admin/patient-list/patient/demographics"
          element={<PatientDemographics />}
        />
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Appoinments" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/appointments"
              element={<PatientAppointments />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Care Team" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/care-team"
              element={<PatientCareTeam />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Encounter" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/encounters"
              element={<PatientEncounters />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Encounter Insurance" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/encounter-insurance"
              element={<PatientEncounterInsurance />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Surgical History" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/surgical-history"
              element={<PatientSurgicalHistory />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Patient Pharmacy" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/patient-pharmacy"
              element={<PatientPharmacy />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Encounter Provider" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/encounter-provider"
              element={<PatientEncounterProvider />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Diagnosis" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/diagnosis"
              element={<PatientDiagnosis />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return menu?.MenuName === "Order" && menu?.IsGrantAccess === true;
          }) && (
            <Route
              path="/admin/patient-list/patient/order"
              element={<PatientOrder />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Problems" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/problems"
              element={<PatientProblems />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Allergies" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/allergies"
              element={<PatientAllergies />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Medications" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/medications"
              element={<PatientMedications />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Immunizations" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/immunizations"
              element={<PatientImmunizations />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Past Medical History" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/past-medical-history"
              element={<PastMedicalHistory />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Family History" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/family-history"
              element={<FamilyHistory />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Social History" &&
              menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/patient/social-history"
              element={<SocialHistory />}
            />
          )}

        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return menu?.MenuName === "Result" && menu?.IsGrantAccess === true;
          }) && (
            <Route
              path="/admin/patient-list/patient/lab-results"
              element={<PatientLabResults />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return menu?.MenuName === "Vital" && menu?.IsGrantAccess === true;
          }) && (
            <Route
              path="/admin/patient-list/:patientId/vital-details"
              element={<VitalDetails />}
            />
          )}
        {MenuDetails &&
          MenuDetails?.some((menu) => {
            return (
              menu?.MenuName === "Documents" && menu?.IsGrantAccess === true
            );
          }) && (
            <Route
              path="/admin/patient-list/:patientId/images"
              element={<PatientDocumentDetails />}
            />
          )}
        <Route path="/admin/reset-password" element={<AdminResetPassword />} />
        <Route
          path="/admin/configure-fields/field-configuration"
          element={<FieldConfiguration />}
        />

        <Route
          path="/admin/configure-fields/client-configuration"
          element={<ClientConfiguration />}
        />
        <Route
          path="/admin/configure-fields/user-configuration"
          element={<UserConfiguration />}
        />
        <Route
          path="/admin/configure-fields/roles-configuration"
          element={<RolesConfiguration />}
        />
        <Route
          path="/admin/configure-fields/menu-configuration"
          element={<MenuConfiguration />}
        />
        {/* <Route
          path="/admin/configure-fields/audit-reports"
          element={<AuditReports />}
        /> */}
      </Route>
    </Routes>
  );
};
