import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  FieldList: [],
  FieldDetail: null,
  FieldDetailsDropdown: {},
  UserList: [],
  UserDetail: {},
  RoleList: [],
  RoleDetail: {},
  RoleRightsList: [],
};

const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    setAdminUserList: (state, action) => {
      state.UserList = action.payload;
    },
    setAdminFieldList: (state, action) => {
      state.FieldList = action.payload;
    },
    setAdminFieldDetail: (state, action) => {
      state.FieldDetail = action.payload;
    },
    setAdminFieldDetailsDropdown: (state, action) => {
      state.FieldDetailsDropdown = action.payload;
    },

    setAdminUserDetail: (state, action) => {
      state.UserDetail = action.payload;
    },
    setAdminRoleList: (state, action) => {
      state.RoleList = action.payload;
    },
    setAdminRoleDetail: (state, action) => {
      state.RoleDetail = action.payload;
    },
    setAdminRoleRightsList: (state, action) => {
      state.RoleRightsList = action.payload;
    },
  },
});

export const {
  setAdminUserList,
  setAdminFieldList,
  setAdminFieldDetail,
  setAdminFieldDetailsDropdown,
  setAdminUserDetail,
  setAdminRoleList,
  setAdminRoleDetail,
  setAdminRoleRightsList,
} = adminSlice.actions;

export default adminSlice.reducer;
