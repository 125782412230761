import React, { useEffect, useMemo, useState } from "react";
import AdminLayout from "../Admin/AdminLayout";
import { useGetSinglePatientDemographicMutation } from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { setPatientDemographicsDetail } from "../../redux/slice/patientSlice";
import "./PatientDemographics.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import moment from "moment";
import Spinner from "../elements/Spinner/Spinner";
import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import UserLayout from "../User/UserLayout";

const PatientDemographics = () => {
  const selectedPatient = localStorage.getItem("patient");
  const patient = selectedPatient && JSON.parse(selectedPatient);
  const encryptedUser = localStorage.getItem("user_details");

  const userDetails =
    encryptedUser &&
    CryptoJS.AES.decrypt(encryptedUser, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);

  const userDetailsData = userDetails && JSON.parse(userDetails);

  const Layout = userDetailsData?.IsAdmin === true ? AdminLayout : UserLayout;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [patientDetails, setPatientDetails] = useState({});
  const [guarantorDetails, setGuarantorDetails] = useState({});
  const [insuranceDetails, setInsuranceDetails] = useState([]);

  const [getSinglePatientDemographic, { isLoading, isFetching }] =
    useGetSinglePatientDemographicMutation();

  const demographicsDetail = useSelector(
    (state) => state.patient.patientDemographicsDetail
  );

  useEffect(() => {
    setLoading(true);
    if (patient?.patientId) {
      const payload = {
        Id: patient?.patientId,
        Name: patient?.Name,
        ChartNumber: patient?.PatientChartNumber,
      };
      getSinglePatientDemographic({ query: "", payload: payload })
        .then((res) => {
          if (res?.data) {
            dispatch(setPatientDemographicsDetail(res?.data));
            setLoading(false);
          } else {
            // Fall back to local storage data if response is empty
            setPatientDetails({
              Name: patient?.Name,
              ChartNumber: patient?.PatientChartNumber,
              ...patient,
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [patient?.patientId]);

  useEffect(() => {
    if (demographicsDetail) {
      setPatientDetails(demographicsDetail?.PatientDetails || {});
      setGuarantorDetails(demographicsDetail?.GuarantorDetails || {});
      setInsuranceDetails(demographicsDetail?.InsuranceDetails || []);
    }
  }, [demographicsDetail]);

  const columns = useMemo(() => {
    if (insuranceDetails?.length > 0) {
      return Object.keys(insuranceDetails[0]).map((key) => ({
        accessorKey: key,
        header: key
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase()),
        size: 160,
        cell: (info) => {
          const value = info.getValue();
          if (
            key === "StartDate" ||
            key === "EndDate" ||
            key === "DateRecorded"
          ) {
            return value ? moment(value).format("MM/DD/YYYY") : "";
          }
          return value;
        },
      }));
    }
    return [];
  }, [insuranceDetails]);

  const table = useMaterialReactTable({
    columns,
    data: insuranceDetails || [],
    enableDensityToggle: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    initialState: {
      density: "compact",
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100],
    },
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#e0e0e0",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#f5f5f5",
        },
      },
    },
  });

  // Ensure each row has a unique id
  const rows = insuranceDetails?.map((appointment, index) => ({
    id: index, // Assign a unique id
    ...appointment,
  }));

  const order = [
    "Name",
    "DOB",
    "SSN",
    "Gender",
    "Address",
    "HomePh",
    "WorkPh",
    "CellPh",
    "Email",
    "Race",
    "Ethinicity",
    "Language",
    "MaritalStatus",
    "Occupation",
    "Physician",
    "ReferingPhysician",
  ];

  const displayNames = {
    Name: "Patient Name",
    DOB: "Date of Birth",
    SSN: "SSN",
    Gender: "Gender",
    Address: "Address",
    HomePh: "Home Phone",
    WorkPh: "Work Phone",
    CellPh: "Cell Phone",
    Email: "Email",
    Race: "Race",
    Ethinicity: "Ethinicity",
    Language: "Language",
    MaritalStatus: "MaritalStatus",
    Occupation: "Occupation",
    Physician: "Physician",
    ReferingPhysician: "Referring Physician",
  };

  const guarantorOrder = [
    "Name",
    "DOB",
    "SSN",
    "Gender",
    "MaritialStatus",
    "Address",
    "HomePh",
    "WorkPh",
    "CellPh",
    "Email",
    "Relationship",
  ];

  const guarantorDisplayNames = {
    Name: "Guarantor Name",
    DOB: "Date of Birth",
    SSN: "SSN",
    Gender: "Gender",
    MaritialStatus: "Marital Status",
    Address: "Address",
    HomePh: "Home Phone",
    WorkPh: "Work Phone",
    CellPh: "Cell Phone",
    Email: "Email",
    Relationship: "Relationship",
  };

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-md-6 left-column">
            <div className="configure-head">
              <div className="same-heading-icon">
                <i className="fa fa-users fe-16 fe-16" />
                <h2>Patient Demographics</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex">
          <div className="col-md-6 left-column">
            <div className="table-section">
              <h3>Patient</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th
                      width="30%"
                      style={{ visibility: "hidden", padding: "0" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {order.map((key) => (
                    <tr key={key}>
                      <td>{displayNames[key]}</td>
                      <td>{patientDetails[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6 right-column">
            <div className="table-section">
              <h3>Guarantor</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th
                      width="30%"
                      style={{ visibility: "hidden", padding: "0" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {guarantorOrder.map((key) => (
                    <tr key={key}>
                      <td>{guarantorDisplayNames[key]}</td>
                      <td>{guarantorDetails[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div></div>

        <div className="container-fluid">
          <div className="row">
            <div className="outer-dash-section">
              <div className="patient-sec">
                <div className="">
                  <div className="row">
                    <div className="col-lg-12" sx={{ padding: "5px" }}>
                      <div className="box-shadow-set">
                        <div className="col-md-12">
                          <div
                            className="common-table"
                            style={{ padding: "15px" }}
                          >
                            <MaterialReactTable table={table} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner isLoading={isFetching || loading} />
    </Layout>
  );
};

export default PatientDemographics;
