import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  UserDetails: {},
  MenuDetails: [],
  MenuList: [],
  clientDetails: {},
  isSearchBarOpen: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      state.loggedInUser = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.isLoggedIn = true;
    },
    verifyOtp: (state, action) => {
      state.isOtpVerified = true;
    },
    logout: (state) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("patient");
    },
    setUserMenuList: (state, action) => {
      state.MenuList = action.payload;
    },
    setClientDetails: (state, action) => {
      state.clientDetails = action.payload;
    },
    toggleSearchBarState: (state, action) => {
      state.isSearchBarOpen = action.payload;
    },
  },
});

export const { logout, login, verifyOtp, toggleSearchBarState } =
  authSlice.actions;

export default authSlice.reducer;
