import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CssBaseline,
  Menu,
  MenuItem,
  Box,
  Grid,
  TextField,
  Button,
  Collapse,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useLocation } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import "./AdminLayout.css";
import { useGetPatientDemographicsonSearchMutation } from "../../services/apiService";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  setPatientList,
  logout,
  setPatientDocumentsCount,
} from "../../redux/slice/patientSlice";
import Spinner from "../elements/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import {
  REACT_APP_BACKEND_URL,
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import { toggleSearchBarState } from "../../redux/slice/authSlice";

const AdminLayout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [isListOpen, setIsListOpen] = useState(true);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState("null");
  const activeItemRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const currentRoute = location.pathname;
    if (currentRoute.includes("/admin/configure-fields/")) {
      setExpandedPanel("configurations");
    } else if (currentRoute.includes("/admin/patient-list/patient/")) {
      setExpandedPanel("clinical");
    } else {
      setExpandedPanel(null);
    }
  }, [location?.pathname]);

  const handlePanelChange = useCallback(
    (panel) => {
      setExpandedPanel(panel);
      activeItemRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    },
    [activeItemRef, setExpandedPanel]
  );

  useLayoutEffect(() => {
    if (expandedPanel !== null && activeItemRef?.current) {
      activeItemRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [expandedPanel, activeItemRef]);
  useEffect(() => {
    if (expandedPanel !== null && activeItemRef?.current) {
      activeItemRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [expandedPanel, activeItemRef, children]);

  const toggleList = () => {
    setIsListOpen(!isListOpen);
  };

  const toggleSearchBar = () => {
    setIsSearchBarOpen(!isSearchBarOpen);
    dispatch(toggleSearchBarState(!isSearchBarOpen));
  };

  const patientDocumentsCount = useSelector(
    (state) => state.patient.patientDocumentsCount
  );

  const [
    getPatientDemographicsonSearch,
    { data: patientList, isLoading, isSuccess, isError, error, isFetching },
  ] = useGetPatientDemographicsonSearchMutation();
  const navigate = useNavigate();
  const encryptedToken = localStorage.getItem("access_token");
  let token = null;
  try {
    if (encryptedToken) {
      token = CryptoJS.AES.decrypt(
        encryptedToken,
        REACT_APP_CRYPTOJS_SECRETKEY,
        {
          iv: REACT_APP_CRYPTOJS_VECTOR,
        }
      ).toString(CryptoJS.enc.Utf8);
    }
  } catch (error) {
    console.error("Error decrypting token:", error);
  }

  const MenuDetails = useMemo(() => {
    const userMenuDetails = localStorage.getItem("menu_details");
    if (userMenuDetails) {
      const userAvailableMenus = CryptoJS.AES.decrypt(
        userMenuDetails,
        REACT_APP_CRYPTOJS_SECRETKEY,
        { iv: REACT_APP_CRYPTOJS_VECTOR }
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(userAvailableMenus);
    }
    return null;
  }, []);

  useEffect(() => {
    if (isSuccess && patientList) {
      dispatch(setPatientList(patientList));
    }
  }, [isSuccess, patientList]);

  const selectedPatient = JSON.parse(localStorage.getItem("patient"));

  const { control, handleSubmit, reset } = useForm();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate("/profile");
    handleClose();
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    handleClose();
  };

  const handleSearch = async (data) => {
    const [firstField] = Object.entries(data).filter(([key, value]) => value);

    const payload = firstField
      ? { FieldName: firstField[0], Search: firstField[1] }
      : {};

    getPatientDemographicsonSearch({
      query: "",
      payload,
    }).then((res) => {
      if (res?.data) {
        localStorage.removeItem("patientId");
        dispatch(setPatientList(res?.data));
        navigate("/admin/patient-list/all");
      }

      reset();
    });
  };

  useEffect(() => {
    if (selectedPatient !== null) {
      fetch(
        `${REACT_APP_BACKEND_URL}/api/Archival/GetTabCounts?patientId=${selectedPatient?.patientId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          dispatch(setPatientDocumentsCount(data?.[0]));
        })
        .catch((error) => console.error("Error fetching table header:", error));
    }
  }, [selectedPatient?.patientId]);

  return (
    <div className="root">
      <CssBaseline />
      <AppBar
        position="fixed"
        className="appBar "
        sx={{
          backgroundColor: "#f8f8f8",
          boxShadow: "none",
          // borderBottom: "3px solid #f5aa50",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleList}
            className="menuButton hover-btn"
            sx={{
              padding: "2px",
              backgroundColor: "#52667b",
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <img
            src="/assets/images/santeware-removebg.png"
            className="img-fluid"
            alt="logo"
            style={{ marginRight: 0, height: 40, width: "auto" }}
          /> */}
          <Box
            sx={{
              // flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#01A3E8",
              marginLeft: "5px",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              sx={{ color: "#000", fontWeight: "bold" }}
            >
              DataHive
            </Typography>
            {/* <Typography
              variant="body2"
              noWrap
              sx={{ color: "#000", fontSize: "0.7rem" }}
            >
              Legacy EMR-Touchworks
            </Typography> */}
          </Box>
          <Box sx={{ displaY: "flex", flexGrow: 1 }}></Box>

          {selectedPatient && (
            <>
              <Chip
                label={`Name: ${selectedPatient.Name || "No record found"}`}
                sx={{
                  marginLeft: 1,
                  color: "#000",
                  border: "1px solid #F0F0F0",
                  borderRadius: "0",
                  backgroundColor: "#fff",
                }}
              />
              <Chip
                label={`DOB: ${
                  selectedPatient.DOB
                    ? moment(selectedPatient.DOB).format("MM-DD-YYYY")
                    : "No record found"
                }`}
                sx={{
                  marginLeft: 0,
                  color: "#000",
                  border: "1px solid #F0F0F0",
                  borderRadius: "0",
                  backgroundColor: "#fff",
                }}
              />
              <Chip
                label={`MRN: ${selectedPatient.MRN || "No record found"}`}
                sx={{
                  marginRight: 1,
                  color: "#000",
                  border: "1px solid #F0F0F0",
                  borderRadius: "0",
                  backgroundColor: "#fff",
                }}
              />
            </>
          )}
          {/* <Divider orientation="vertical" flexItem /> */}
          <IconButton
            size="large"
            aria-label="expand search bar"
            onClick={toggleSearchBar}
            color="inherit"
            className="hover-btn"
            sx={{
              padding: "2px",
              backgroundColor: "#52667b",
              marginRight: "5px",
            }}
          >
            {isSearchBarOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{
              backgroundColor: "transparent",
              height: "64px",
              width: "64px",
              borderRadius: "50%",
              color: "#000",
            }}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            sx={{ top: "32px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
        <Collapse in={isSearchBarOpen} timeout={0}>
          <Box
            sx={{
              padding: "8px",
              backgroundColor: "#Fff",
              position: "fixed",
              top: "64px",
              zIndex: "1",
            }}
          >
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid container spacing={2} alignItems="center">
                {[
                  {
                    name: "Name",
                    label: "Name",
                    placeholder: "Name",
                  },
                  {
                    name: "SSN",
                    label: "SSN",
                    placeholder: "SSN",
                  },
                  { name: "DOB", label: "DOB", placeholder: "MM/DD/YYYY" },
                  { name: "MRN", label: "MRN", placeholder: "MRN" },
                  {
                    name: "PatientChartNumber",
                    label: "Visit Number",
                    placeholder: "Visit Number",
                  },
                ].map((field, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                    <Controller
                      name={field.name}
                      control={control}
                      render={({ field: controllerField }) => (
                        <TextField
                          {...controllerField}
                          fullWidth
                          label={field.label}
                          placeholder={field.placeholder}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    type="submit"
                    sx={{
                      backgroundColor: "#1565c0",
                      borderColor: "transparent",
                    }}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Collapse>
      </AppBar>
      <div
        className={`list-container ${
          isListOpen ? "list-open" : "list-closed"
        } ${isSearchBarOpen ? "list-shifted-top" : ""}`}
      >
        <List>
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#52667b",
              borderBottom: "3px solid #f5aa50",
              justifyContent: "center",
              alignItems: "center",
              padding: "6px 0",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Legacy EMR-Touchworks
            </Typography>
          </Box>
          <Accordion
            sx={{ margin: 0 }}
            disableGutters
            expanded={expandedPanel === "clinical"} // Manage expansion state using state variable
            onChange={() => handlePanelChange("clinical")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ margin: 0 }}
            >
              {
                <LocalHospitalIcon
                  sx={{ color: "#f5aa50", marginRight: "5px" }}
                />
              }
              <Typography>Clinical</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, margin: 0 }}>
              <List component="div" disablePadding>
                {[
                  {
                    text: "Patient Search",
                    path: "/admin/patient-list/all",
                  },
                ].map((item, index) => (
                  <ListItem
                    key={index}
                    component={item.path ? NavLink : "div"}
                    to={item.path}
                    activeClassName="active"
                    ref={
                      location?.pathname === item?.path ? activeItemRef : null
                    }
                  >
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
                {selectedPatient?.patientId !== "" &&
                  selectedPatient?.patientId !== undefined &&
                  selectedPatient?.patientId !== null && (
                    <>
                      <ListItem
                        component={NavLink}
                        to={`/admin/patient-list/patient/demographics`}
                        activeClassName="active"
                        ref={
                          location?.pathname ===
                          "/admin/patient-list/patient/demographics"
                            ? activeItemRef
                            : null
                        }
                      >
                        <ListItemText primary="Demographics" />

                        {patientDocumentsCount !== null && (
                          <Chip
                            label={patientDocumentsCount?.Demographics}
                            sx={{
                              color: "#fff",
                              fontSize: "4px",
                              borderRadius: "0",
                              padding: 0,
                            }}
                          />
                        )}
                      </ListItem>
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Encounter" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/encounters`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/encounters"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Encounters" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.Encounters}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Encounter Insurance" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/encounter-insurance`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/encounter-insurance"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Encounter Insurance" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={
                                  patientDocumentsCount?.["Encounter Insurance"]
                                }
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Encounter Provider" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/encounter-provider`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/encounter-provider"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Encounter Provider" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={
                                  patientDocumentsCount?.["Encounter Provider"]
                                }
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Allergies" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/allergies`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/allergies"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Allergies" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.Allergies}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Diagnosis" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/diagnosis`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/diagnosis"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Diagnosis" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.["Diagnosis"]}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Immunizations" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/immunizations`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/immunizations"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Immunizations" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.Immunizations}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Order" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/order`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/order"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Order" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.Order}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Result" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/lab-results`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/lab-results"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Lab Results" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.["Results"]}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Medications" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/medications`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/medications"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Medications" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.Medications}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Problems" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/problems`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/problems"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Problems" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.Problems}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Past Medical History" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/past-medical-history`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/past-medical-history"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Medical History" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={
                                  patientDocumentsCount?.[
                                    "Past Medical History"
                                  ]
                                }
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Family History" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/family-history`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              "/admin/patient-list/patient/family-history"
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Family History" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={
                                  patientDocumentsCount?.["Family History"]
                                }
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Social History" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/social-history`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              `/admin/patient-list/patient/social-history`
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Social History" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={
                                  patientDocumentsCount?.["Social History"]
                                }
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}

                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Surgical History" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/surgical-history`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              `/admin/patient-list/patient/surgical-history`
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Surgical History" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={
                                  patientDocumentsCount?.["Surgical History"]
                                }
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Vital" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/vital-details`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              `/admin/patient-list/patient/vital-details`
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Vital Details" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.["Vital Details"]}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Documents" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/images`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              `/admin/patient-list/patient/images`
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Documents" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.["Documents"]}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}

                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Care Team" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/care-team`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              `/admin/patient-list/patient/care-team`
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Care Team" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.["Care Team"]}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}

                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Patient Pharmacy" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/patient-pharmacy`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              `/admin/patient-list/patient/patient-pharmacy`
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Pharmacy" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.["Pharmacy"]}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}

                      {MenuDetails &&
                        MenuDetails?.some((menu) => {
                          return (
                            menu?.MenuName === "Appoinments" &&
                            menu?.IsGrantAccess === true
                          );
                        }) && (
                          <ListItem
                            component={NavLink}
                            to={`/admin/patient-list/patient/appointments`}
                            activeClassName="active"
                            ref={
                              location?.pathname ===
                              `/admin/patient-list/patient/appointments`
                                ? activeItemRef
                                : null
                            }
                          >
                            <ListItemText primary="Appointments" />
                            {patientDocumentsCount !== null && (
                              <Chip
                                label={patientDocumentsCount?.Appointments}
                                sx={{
                                  color: "#fff",
                                  fontSize: "8px",
                                  borderRadius: "0",

                                  padding: 0,
                                }}
                              />
                            )}
                          </ListItem>
                        )}
                    </>
                  )}
              </List>
            </AccordionDetails>
          </Accordion>
          {/*  */}
          <Accordion
            sx={{ margin: 0 }}
            disableGutters
            expanded={expandedPanel === "financial"} // Manage expansion state using state variable
            onChange={() => handlePanelChange("financial")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ margin: 0 }}
            >
              <CurrencyExchangeIcon
                sx={{ color: "#f5aa50", marginRight: "5px" }}
              />
              <Typography>Financial</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <List component="div" disablePadding sx={{ margin: 0 }}></List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: 0 }}
            disableGutters
            expanded={expandedPanel === "organ"} // Manage expansion state using state variable
            onChange={() => handlePanelChange("organ")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ margin: 0 }}
            >
              <VolunteerActivismIcon
                sx={{ color: "#f5aa50", marginRight: "5px" }}
              />
              <Typography>Organ Transplant</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <List component="div" disablePadding sx={{ margin: 0 }}></List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ margin: 0 }}
            disableGutters
            expanded={expandedPanel === "blood"} // Manage expansion state using state variable
            onChange={() => handlePanelChange("blood")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ margin: 0 }}
            >
              <BloodtypeIcon sx={{ color: "#f5aa50", marginRight: "5px" }} />
              <Typography>Blood Bank</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <List component="div" disablePadding sx={{ margin: 0 }}></List>
            </AccordionDetails>
          </Accordion>

          {/*  */}
          <Accordion
            sx={{ margin: 0 }}
            disableGutters
            expanded={expandedPanel === "configurations"} // Manage expansion state using state variable
            onChange={() => handlePanelChange("configurations")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ margin: 0 }}
            >
              <SettingsIcon sx={{ color: "#f5aa50", marginRight: "5px" }} />
              <Typography>Configurations</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <List component="div" disablePadding sx={{ margin: 0 }}>
                {[
                  {
                    text: "Client Configuration",
                    path: "/admin/configure-fields/client-configuration",
                  },
                  {
                    text: "Roles Configuration",
                    path: "/admin/configure-fields/roles-configuration",
                  },
                  {
                    text: "User Configuration",
                    path: "/admin/configure-fields/user-configuration",
                  },
                  {
                    text: "Field Configuration",
                    path: "/admin/configure-fields/field-configuration",
                  },

                  {
                    text: "Menu Configuration",
                    path: "/admin/configure-fields/menu-configuration",
                  },
                  // {
                  //   text: "Audit Reports",
                  //   path: "/admin/configure-fields/audit-reports",
                  // },
                ].map((item, index) => (
                  <ListItem
                    key={index}
                    component={NavLink}
                    to={item.path}
                    activeClassName="active"
                    button
                    ref={
                      location?.pathname === item?.path ? activeItemRef : null
                    }
                  >
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </List>
      </div>
      <main
        className={`content 
    ${isListOpen ? "content-shifted-left" : ""} 
    ${isSearchBarOpen ? "content-shifted-top" : ""}`}
      >
        <div className="toolbar" />
        {children}
      </main>
      <Spinner isLoading={isFetching} />
    </div>
  );
};

export default AdminLayout;
