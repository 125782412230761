import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patient: {},
  Patients: [],
  PatientList: [],
  patientDemographicsDetail: null,
  patientAppointMentsDetail: null,
  patientEncounterDetail: null,
  patientLabResultDetail: null,
  patientProblemDetail: null,
  patientAllergiesDetail: null,
  patientMedicationDetail: null,
  patientOrder: null,
  patientCareTeam: null,
  patientEncounterInsurance: null,
  patientSurgicalHistory: null,
  patientPharmacy: null,
  patientEncounterProvider: null,
  patientDiagnosis: null,
  patientImmunizationDetail: null,
  patientPastMedicalHistoryDetail: null,
  patientFamilyHistoryDetail: null,
  patientSocialHistoryDetail: null,
  patientVitalDetail: null,
  patientDocumentsDetail: null,
  patientDocumentsCount: null,
  latestPatients: [],
  TableHeader: "",
  loggedInUser: {},
  token: null,
  refreshToken: null,
  isLoggedIn: false,
  isOtpVerified: false,
  currentUser: null,
  MenuList: [],
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    login: (state, action) => {
      state.loggedInUser = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.isLoggedIn = true;
    },
    verifyOtp: (state) => {
      state.isOtpVerified = true;
    },
    logout: (state) => {
      state.loggedInUser = {};
      state.token = null;
      state.refreshToken = null;
      state.isLoggedIn = false;
      state.isOtpVerified = false;
      state.currentUser = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
    },
    setUserMenuList: (state, action) => {
      state.MenuList = action.payload;
    },
    setPatientDetails: (state, action) => {
      state.patient = action.payload;
    },
    setPatientDocumentsCount: (state, action) => {
      state.patientDocumentsCount = action.payload;
    },
    setLatestPatients: (state, action) => {
      state.latestPatients = action.payload;
    },
    setTableHeader: (state, action) => {
      state.TableHeader = action.payload;
    },
    setPatientList: (state, action) => {
      state.PatientList = action.payload;
    },
    setPatientDemographicsDetail: (state, action) => {
      state.patientDemographicsDetail = action.payload;
    },
    setPatientAppointmentDetail: (state, action) => {
      state.patientAppointMentsDetail = action.payload;
    },
    setPatientEncounterDetail: (state, action) => {
      state.patientEncounterDetail = action.payload;
    },
    setPatientLabResultDetail: (state, action) => {
      state.patientLabResultDetail = action.payload;
    },
    setPatientProblemDetail: (state, action) => {
      state.patientProblemDetail = action.payload;
    },
    setPatientAllergiesDetail: (state, action) => {
      state.patientAllergiesDetail = action.payload;
    },
    setPatientMedicationDetail: (state, action) => {
      state.patientMedicationDetail = action.payload;
    },
    setPatientImmunizationDetail: (state, action) => {
      state.patientImmunizationDetail = action.payload;
    },
    setPatientPastMedicalHistoryDetail: (state, action) => {
      state.patientPastMedicalHistoryDetail = action.payload;
    },
    setPatientFamilyHistoryDetail: (state, action) => {
      state.patientFamilyHistoryDetail = action.payload;
    },
    setPatientSocialHistoryDetail: (state, action) => {
      state.patientSocialHistoryDetail = action.payload;
    },
    setPatientVitalDetail: (state, action) => {
      state.patientVitalDetail = action.payload;
    },
    setPatientDocumentsDetail: (state, action) => {
      state.patientDocumentsDetail = action.payload;
    },
    setPatientOrderDetail: (state, action) => {
      state.patientOrder = action.payload;
    },
    setPatientCareTeamDetail: (state, action) => {
      state.patientCareTeam = action.payload;
    },
    setPatientEncounterInsuranceDetail: (state, action) => {
      state.patientEncounterInsurance = action.payload;
    },
    setPatientSurgicalHistoryDetail: (state, action) => {
      state.patientSurgicalHistory = action.payload;
    },
    setPatientPharmacyDetail: (state, action) => {
      state.patientPharmacy = action.payload;
    },

    setPatientEncounterProviderDetail: (state, action) => {
      state.patientEncounterProvider = action.payload;
    },
    setPatientDiagnosisDetail: (state, action) => {
      state.patientDiagnosis = action.payload;
    },
  },
});

export const {
  logout,
  login,
  verifyOtp,
  setUserMenuList,
  setPatientDetails,
  setPatientDocumentsCount,
  setLatestPatients,
  setTableHeader,
  setPatientList,
  setPatientDemographicsDetail,
  setPatientAppointmentDetail,
  setPatientEncounterDetail,
  setPatientLabResultDetail,
  setPatientProblemDetail,
  setPatientAllergiesDetail,
  setPatientMedicationDetail,
  setPatientImmunizationDetail,
  setPatientPastMedicalHistoryDetail,
  setPatientFamilyHistoryDetail,
  setPatientSocialHistoryDetail,
  setPatientVitalDetail,
  setPatientDocumentsDetail,
  setPatientOrderDetail,
  setPatientCareTeamDetail,
  setPatientEncounterInsuranceDetail,
  setPatientSurgicalHistoryDetail,
  setPatientPharmacyDetail,
  setPatientDiagnosisDetail,
  setPatientEncounterProviderDetail,
} = patientSlice.actions;

export default patientSlice.reducer;
