import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import CheckIcon from "@mui/icons-material/Check"; // For "true" state
import CloseIcon from "@mui/icons-material/Close"; // For "false" state

import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import AdminLayout from "../Admin/AdminLayout";
import { Box, Button, Typography, IconButton } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  useGetFieldDetailsDropdownFromMenuIdMutation,
  useGetFieldDetailsFromMenuIdMutation,
  useGetUserMenuListMutation,
} from "../../services/apiService";
import {
  setAdminFieldDetailsDropdown,
  setAdminFieldList,
} from "../../redux/slice/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUserMenuList } from "../../redux/slice/patientSlice";
import AddFieldModal from "../AddFieldModal/AddFieldModal";
import UpdateFieldModal from "../UpdateFieldModal/UpdateFieldModal";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "../elements/Spinner/Spinner";

const colourStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "220px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
    zIndex: 9999,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "150px",
    minHeight: "30px",
    zIndex: "1",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const FieldConfiguration = () => {
  const [menuList, setMenuList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [fieldList, setFieldList] = useState([]);
  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [showUpdateFieldModal, setShowUpdateFieldModal] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [dropdownList, setDropdownList] = useState([]);

  const isSearchBarOpen = useSelector((state) => state.auth.isSearchBarOpen);
  const [delayedMaxHeight, setDelayedMaxHeight] = useState(
    "calc(100vh - 238px)"
  );

  const dispatch = useDispatch();

  const currentUser = useMemo(() => {
    const loggedInUserDetail = localStorage.getItem("user_details");
    if (loggedInUserDetail) {
      const loggedInUser = CryptoJS.AES.decrypt(
        loggedInUserDetail,
        REACT_APP_CRYPTOJS_SECRETKEY,
        { iv: REACT_APP_CRYPTOJS_VECTOR }
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(loggedInUser);
    }
    return null;
  }, []);

  const [
    getUserMenuList,
    { isFetching: isMenuListFetching, isLoading: isMenuListLoading },
  ] = useGetUserMenuListMutation();

  const [getFieldDetailsFromMenuId, { isFetching, isLoading }] =
    useGetFieldDetailsFromMenuIdMutation();

  const [
    getFieldDetailsDropdownFromMenuId,
    { isFetching: isDropdownFetching, isLoading: isDropdownLoading },
  ] = useGetFieldDetailsDropdownFromMenuIdMutation();

  const handleMenuChange = (selectedOption) => {
    setSelectedMenu(selectedOption);
    getFieldDetailsFromMenuId({ query: selectedOption?.MenuId }).then((res) => {
      dispatch(setAdminFieldList(res?.data));
      setFieldList(res?.data);
    });
    getFieldDetailsDropdownFromMenuId({
      query: selectedOption?.MenuId,
      payload: null,
    }).then((response) => {
      setDropdownList(response?.data?.FieldList);
      dispatch(setAdminFieldDetailsDropdown(response?.data?.FieldList));
    });
  };

  useEffect(() => {
    getUserMenuList({ query: currentUser?.UserName, payload: "" }).then(
      (res) => {
        dispatch(setUserMenuList(res?.data));
        setMenuList(res?.data);
        const selectedMenu = res?.data?.find(
          (menu) => menu.MenuName === "Patient List"
        );
      }
    );
  }, [currentUser?.UserName]);

  const handleEdit = (id) => {
    setSelectedFieldId(id);
    setShowUpdateFieldModal(true);
  };

  const openAddFieldModal = () => {
    setShowAddFieldModal(true);
  };

  const closeAddFieldModal = () => {
    setShowAddFieldModal(false);
    if (selectedMenu !== null) {
      // Re-fetch the field list after closing the modal
      getFieldDetailsFromMenuId({
        query: selectedMenu?.MenuId,
        payload: null,
      }).then((res) => {
        dispatch(setAdminFieldList(res?.data));
        setFieldList(res?.data);
      });
    }
  };

  const closeUpdateFieldModal = () => {
    setShowUpdateFieldModal(false);
    if (selectedMenu !== null) {
      // Re-fetch the dropdown list after closing the modal
      getFieldDetailsFromMenuId({
        query: selectedMenu?.MenuId,
        payload: null,
      }).then((res) => {
        dispatch(setAdminFieldList(res?.data));
        setFieldList(res?.data);
      });
    }
  };

  const columns = useMemo(() => {
    // Create the columns from the fieldList
    const generatedColumns =
      fieldList?.length > 0
        ? Object.keys(fieldList[0]).map((key) => {
            if (key === "IsActive") {
              // Special handling for IsActive column
              return {
                accessorKey: key,
                header: key,
                size: 100,
                Cell: ({ cell }) => {
                  const isActive = cell.getValue();
                  return isActive ? (
                    <CheckIcon style={{ color: "green" }} />
                  ) : (
                    <CloseIcon style={{ color: "red" }} />
                  );
                },
              };
            }
            // Default handling for other columns
            return {
              accessorKey: key,
              header: key,
              size: 200,
              Cell: ({ cell }) => cell.getValue(),
            };
          })
        : [];

    // Conditionally add the Actions column at the end

    generatedColumns?.length > 0 &&
      generatedColumns.push({
        accessorKey: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <IconButton
            onClick={() => {
              handleEdit(row.original.ID);
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        ),
      });

    return generatedColumns;
  }, [fieldList, handleEdit]);

  useEffect(() => {
    if (isSearchBarOpen) {
      setDelayedMaxHeight("calc(100vh - 296px)");
    } else {
      const timer = setTimeout(() => {
        setDelayedMaxHeight("calc(100vh - 238px)");
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isSearchBarOpen]);

  const table = useMaterialReactTable({
    columns,
    data: (fieldList?.length > 0 && fieldList) || [],
    enableDensityToggle: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    initialState: {
      density: "compact",
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableContainerProps: {
      sx: {
        minHeight: 180,
        maxHeight: delayedMaxHeight,
        transition: "max-height 0.5s ease-in-out",
        zIndex: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #f5aa50",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        "& .Mui-TableHeadCell-Content-Labels": {
          padding: "0px",
        },
        "& .MuiBox-root": {
          padding: "0px",
        },
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
    },
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#e0e0e0",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          overflow: "visible",
        }}
      >
        <Box>
          <Select
            placeholder="Select Field"
            styles={colourStyles}
            isRequired
            onChange={handleMenuChange}
            value={selectedMenu}
            options={menuList}
            getOptionLabel={(menu) => menu.MenuName}
            getOptionValue={(menu) => menu.MenuId}
            className="select-field"
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedMenu}
          onClick={openAddFieldModal}
          className="common-btn"
        >
          Add new field
        </Button>
      </Box>
    ),
  });

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="outer-dash-section">
            <div className="patient-sec">
              <div className="">
                <div className="row">
                  <div className="col-lg-12 left-column">
                    <div className="box-shadow-set p-2">
                      <div className="configure-head">
                        <div className="same-heading-icon">
                          <i className="fa fa-file-medical-alt fe-16" />
                          <h2>Field Configuration</h2>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="common-table">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddFieldModal
        open={showAddFieldModal}
        closeAddFieldModal={closeAddFieldModal}
        dropdownList={dropdownList}
        MenuId={selectedMenu?.MenuId}
      />

      <UpdateFieldModal
        open={showUpdateFieldModal}
        FieldId={selectedFieldId}
        closeUpdateFieldModal={closeUpdateFieldModal}
        dropdownList={dropdownList}
        MenuId={selectedMenu?.MenuId}
      />
      <Spinner
        isLoading={
          isLoading ||
          isFetching ||
          isMenuListFetching ||
          isMenuListLoading ||
          isDropdownFetching ||
          isDropdownLoading
        }
      />
    </AdminLayout>
  );
};

export default FieldConfiguration;
