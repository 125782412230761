import { useEffect } from "react";
import "./App.css";
import AdminLayout from "./components/Admin/AdminLayout";
import { BaseRoutes } from "./routes";
import { useGetClientDetailsQuery } from "./services/apiService";

function App() {
  const {
    data: clientDetails,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useGetClientDetailsQuery();

  return <BaseRoutes />;
}

export default App;
