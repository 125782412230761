import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import {
  useGetUserByUserIdMutation,
  usePostUpdateUserMutation,
} from "../../services/apiService";
import { useDispatch } from "react-redux";
import { setAdminUserDetail } from "../../redux/slice/adminSlice";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserSchema } from "../../utils/validationSchema/validation";

const UpdateUserModal = ({ open, userId, closeUpdateUserModal, roleList }) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      FirstName: "",
      LastName: "",
      Title: "",
      Department: "",
      Email: "",
      Phone: "",
      UserName: "",
      Password: "", // Leave blank or provide a placeholder
      Role: null, // Set as null for the Select component
      IsActive: false,
    },
    mode: "onBlur",
    resolver: yupResolver(updateUserSchema),
  });

  const [
    postUpdateUser,
    { isFetching: isUpdating, isLoading: isUpdateLoading },
  ] = usePostUpdateUserMutation();

  const [
    getUserByUserId,
    { isFetching: isDetailsFetching, isLoading: isDetailsLoading },
  ] = useGetUserByUserIdMutation();

  useEffect(() => {
    if (userId) {
      getUserByUserId({ query: userId, payload: null }).then((res) => {
        const details = res?.data;
        if (details) {
          // Dispatch to update the redux store
          dispatch(setAdminUserDetail(details));

          console.log("RoleList", roleList);

          console.log(
            "details",
            roleList &&
              roleList.find((role) => role.RoleId === parseInt(details?.Role))
          );

          reset({
            FirstName: details.FirstName,
            LastName: details.LastName,
            Title: details.Title,
            Department: details.Department,
            Email: details.Email,
            Phone: details.Phone,
            UserName: details.UserName,
            Role:
              roleList &&
              roleList.find((role) => role.RoleId === parseInt(details?.Role))
                ?.RoleId, // Set selected role
            IsActive: details.IsActive,
          });
        }
      });
    }
  }, [userId, getUserByUserId, dispatch, reset, roleList]);

  const handleUpdateUser = (values) => {
    const params = {
      Id: userId, // Use the userId for updating
      FirstName: values.FirstName,
      LastName: values.LastName,
      Title: values.Title,
      Department: values.Department,
      Email: values.Email,
      Phone: values.Phone,
      UserName: values.UserName,
      Password: values.Password, // Optional: If updating the password, otherwise can be ignored
      RoleId: values.Role, // Use the selected RoleId
      IsActive: values.IsActive,
    };
    console.log("values", values);
    postUpdateUser({ query: userId, payload: params }).then((res) => {
      toast.success("User details updated successfully");
      reset();
      closeUpdateUserModal();
    });
  };

  return (
    <Modal open={open} onClose={closeUpdateUserModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            marginBottom: "20px",
            color: "#212121",
          }}
        >
          Update User
        </Typography>
        <Box>
          <form onSubmit={handleSubmit(handleUpdateUser)}>
            <div className="row" style={{ gap: "10px" }}>
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="FirstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name*"
                      fullWidth
                      variant="outlined"
                      error={!!errors.FirstName}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.FirstName ? errors.FirstName.message : ""}
                </FormHelperText>
              </div>
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="LastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name*"
                      fullWidth
                      variant="outlined"
                      error={!!errors.LastName}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.LastName ? errors.LastName.message : ""}
                </FormHelperText>
              </div>
            </div>
            <div className="row" style={{ gap: "10px" }}>
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="Title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Title"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="Department"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Department"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row" style={{ gap: "10px" }}>
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="Email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email*"
                      fullWidth
                      variant="outlined"
                      type="email"
                      error={!!errors.Email}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.Email ? errors.Email.message : ""}
                </FormHelperText>
              </div>
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="Phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row" style={{ gap: "10px" }}>
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="UserName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Username*"
                      fullWidth
                      variant="outlined"
                      error={!!errors.UserName}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.UserName ? errors.UserName.message : ""}
                </FormHelperText>
              </div>
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="Password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      fullWidth
                      variant="outlined"
                      type="password"
                      error={!!errors.Password}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.Password ? errors.Password.message : ""}
                </FormHelperText>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="Role"
                  control={control}
                  render={({ field }) => {
                    console.log("field", field);
                    return (
                      <Select
                        {...field}
                        placeholder="Select Role"
                        getOptionLabel={(role) => role.Role}
                        getOptionValue={(role) => role.RoleId}
                        options={roleList}
                        onChange={(selectedOption) => {
                          console.log("selectedOption", selectedOption);
                          field.onChange(selectedOption.RoleId);
                        }}
                        value={roleList.find((option) => {
                          console.log("option", option);
                          return option.RoleId === field?.value;
                        })}
                        isClearable
                        required
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="row" style={{ gap: "10px", marginBottom: "20px" }}>
              <div className="mb-3">
                <FormControlLabel
                  control={
                    <Controller
                      name="IsActive"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Is Active"
                />
              </div>
            </div>
            <Box sx={{ gap: "10px", display: "flex" }}>
              <Button onClick={closeUpdateUserModal} className="common-btn">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="common-btn"
                disabled={isUpdating || isUpdateLoading}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateUserModal;
