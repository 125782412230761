import * as yup from "yup";

// login schema
const loginSchema = yup.object().shape({
  username: yup.string().trim().required("Username is required"),
  password: yup.string().trim().required("Password is required"),
});

const addFieldSchema = yup.object().shape({
  FieldName: yup.string().trim().required("Field Name is required"),
  SortOrder: yup.string().trim().required("Sort Order is required"),
});
const updateFieldSchema = yup.object().shape({
  FieldName: yup.string().trim().required("Field Name is required"),
  SortOrder: yup.string().trim().required("Sort Order is required"),
});

const addRoleSchema = yup.object().shape({
  RoleName: yup.string().trim().required("Role Name is required"),
});
const updateRoleSchema = yup.object().shape({
  RoleName: yup.string().trim().required("Role Name is required"),
});
const addUserSchema = yup.object().shape({
  FirstName: yup.string().trim().required("First Name is required"),
  LastName: yup.string().trim().required("Last Name is required"),
  Email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid email"),
  UserName: yup.string().trim().required("User Name is required"),
  Password: yup.string().trim().required("Password is required"),
});
const updateUserSchema = yup.object().shape({
  FirstName: yup.string().trim().required("First Name is required"),
  LastName: yup.string().trim().required("Last Name is required"),
  Email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Invalid email"),
  UserName: yup.string().trim().required("User Name is required"),
  Password: yup.string().trim().required("Password is required"),
});

export {
  loginSchema,
  addFieldSchema,
  updateFieldSchema,
  addRoleSchema,
  updateRoleSchema,
  addUserSchema,
  updateUserSchema,
};
