import React, { useState } from "react";
import CryptoJS from "crypto-js";
import { useForm, Controller } from "react-hook-form";

import {
  Container,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff, Lock, Person } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  usePostTokenFetchMutation,
  usePostUserLoginMutation,
} from "../../services/apiService";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import { toast } from "react-toastify";
import { loginSchema } from "../../utils/validationSchema/validation";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    postUserLogin,
    { isLoading: isLoginLoading, isFetching: isLoginFetching },
  ] = usePostUserLoginMutation();
  const [postTokenFetch, { isLoading, isFetching }] =
    usePostTokenFetchMutation();

  const initialValues = {
    password: "",
    username: "",
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "onBlur",
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data) => {
    const formData = new URLSearchParams();
    formData.append("username", data?.username);
    formData.append("password", data?.password);
    formData.append("grant_type", "password");

    postTokenFetch({ query: "", payload: formData })
      .then((tokenResponse) => {
        if (tokenResponse?.data?.access_token) {
          localStorage.setItem(
            "access_token",
            CryptoJS.AES.encrypt(
              tokenResponse?.data?.access_token,
              REACT_APP_CRYPTOJS_SECRETKEY,
              { iv: REACT_APP_CRYPTOJS_VECTOR }
            ).toString()
          );

          return postUserLogin({
            query: "",
            payload: { username: data?.username, password: data?.password },
          });
        } else {
          throw new Error("Token fetch failed");
        }
      })
      .then((loginResponse) => {
        if (loginResponse?.data?.UserDetails) {
          localStorage.setItem(
            "user_details",
            CryptoJS.AES.encrypt(
              JSON.stringify(loginResponse?.data?.UserDetails),
              REACT_APP_CRYPTOJS_SECRETKEY,
              { iv: REACT_APP_CRYPTOJS_VECTOR }
            ).toString()
          );
          localStorage.setItem(
            "menu_details",
            CryptoJS.AES.encrypt(
              JSON.stringify(loginResponse?.data?.Menudetails),
              REACT_APP_CRYPTOJS_SECRETKEY,
              { iv: REACT_APP_CRYPTOJS_VECTOR }
            ).toString()
          );

          navigate(
            `/${
              loginResponse?.data?.UserDetails?.IsAdmin === true
                ? "admin"
                : "user"
            }/patient-list/all`
          );
        }
      })
      .catch((error) => {
        console.error("Login error:", error);
        toast.error("An error occurred during login");
      });
  };

  return (
    <>
      <>
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#F8F8F8",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="end"
            sx={{ padding: "10px 0" }}
          >
            <Grid
              item
              md={5}
              className="response-image"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box textAlign="end">
                <img
                  src="/assets/images/santeware-removebg.png"
                  className="img-fluid"
                  alt="icon"
                  style={{
                    maxWidth: "80%",
                    marginRight: "10px",
                    width: "auto",
                    height: "45px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box
            className="login-section"
            sx={{
              minHeight: "calc(100vh - 121px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <Container>
              <Grid
                item
                md={5}
                xs={12}
                className="shadow-box"
                sx={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: "8px",
                  padding: "2rem",
                  boxShadow: "0 3px 10px rgba(0,0,0,0.1)",
                  maxWidth: "500px",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 2,
                    marginBottom: 3,
                    borderBottom: "1px solid #ddd",
                    paddingBottom: "2rem",
                  }}
                >
                  <Lock sx={{ fontSize: 32, color: "#707070" }} />
                  Sign in
                </Typography>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Box className="login-form">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                          control={control}
                          name="username"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Username"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              error={!!errors.username}
                              helperText={
                                errors.username ? errors.username.message : ""
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Person />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="password"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Password"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              type={showPassword ? "text" : "password"}
                              error={!!errors.password}
                              helperText={
                                errors.password ? errors.password.message : ""
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Lock />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={toggleShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{
                            mt: 2,
                            backgroundColor: "#F1AC4F",
                            padding: "0.7rem",
                            fontWeight: "600",
                          }}
                          disabled={
                            isLoginLoading ||
                            isLoginFetching ||
                            isLoading ||
                            isFetching
                          }
                          startIcon={
                            isLoginLoading ||
                            isLoginFetching ||
                            isLoading ||
                            isFetching ? (
                              <CircularProgress size={20} />
                            ) : null
                          }
                        >
                          {isLoginLoading ||
                          isLoginFetching ||
                          isLoading ||
                          isFetching
                            ? "Logging in..."
                            : "Submit"}
                        </Button>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            component="footer"
            sx={{
              backgroundColor: "#F8F8F8",
              textAlign: "center",
              padding: "1rem",
              mt: "auto",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              © {new Date().getFullYear()} Santeware Healthcare Solutions. All
              rights reserved.
            </Typography>
          </Box>
        </Box>
      </>
    </>
  );
};

export default Login;
