import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Grid, Typography, Avatar } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import AdminLayout from "../Admin/AdminLayout";

import {
  useGetClientDetailsQuery,
  usePostClientDetailsMutation,
} from "../../services/apiService";
import { toast } from "react-toastify";
import Spinner from "../elements/Spinner/Spinner";

const ClientConfiguration = () => {
  const {
    handleSubmit,
    watch,
    register,
    handleBlur,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ClientName: "",
      Logo: null,
    },
    mode: "onBlur",
    // resolver: yupResolver(SuperAdminAddUserSchema),
  });

  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarError, setAvatarError] = useState("");

  const { data: clientDetails, isLoading: isClientLoading } =
    useGetClientDetailsQuery();

  const [
    postClientDetails,
    { isLoading, isSuccess, isError, error, isFetching },
  ] = usePostClientDetailsMutation();

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1.5 * 1024 * 1024) {
      if (file.type === "image/png") {
        setValue("Logo", file);
        setAvatarPreview(URL.createObjectURL(file));
        setAvatarError("");
      } else {
        setAvatarError("File must be in PNG format.");
      }
    } else {
      setAvatarError("File size must be less than 1.5 MB.");
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("ClientName", data.ClientName);
    formData.append("Logo", data.Logo);

    postClientDetails({ query: "", payload: formData }).then((res) => {
      if (res?.data?.user_data) {
        toast.success("Client details updated successfully");
      }
    });
  };

  return (
    <AdminLayout>
      <div className="main-content">
        <Typography variant="h4" className="common-head" gutterBottom>
          Client Configuration
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="ClientName"
                control={control}
                rules={{ required: "Client Name is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Client Name"
                    fullWidth
                    error={!!errors.ClientName}
                    helperText={errors.ClientName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                className="common-btn"
                startIcon={<PhotoCamera />}
              >
                Upload Logo
                <input
                  type="file"
                  accept=".png"
                  hidden
                  onChange={handleAvatarChange}
                />
              </Button>
              {avatarError && (
                <Typography color="error" variant="body2">
                  {avatarError}
                </Typography>
              )}
              {!avatarError && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="content-para"
                >
                  File size should not exceed 1.5 MB.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {(avatarPreview || control._formValues?.Logo) && (
                <Avatar
                  src={
                    avatarPreview ||
                    process.env.REACT_APP_FILE_URL + control._formValues?.Logo
                  }
                  alt="Avatar Preview"
                  sx={{ width: 100, height: 100 }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="comon-btn"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <Spinner
        isLoading={isLoading || isFetching || isClientLoading || isSuccess}
      />
    </AdminLayout>
  );
};

export default ClientConfiguration;
