import React, { useEffect, useMemo, useState } from "react";
import AdminLayout from "../Admin/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check"; // For "true" state
import CloseIcon from "@mui/icons-material/Close"; // For "false" state
import CryptoJS from "crypto-js";
import { Box, Button, Typography, IconButton } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import EditIcon from "@mui/icons-material/Edit";
import { useGetRolesListMutation } from "../../services/apiService";
import { setAdminRoleList } from "../../redux/slice/adminSlice";
import AddRoleModal from "../AddRoleModal/AddRoleModal";
import UpdateRoleModal from "../UpdateRoleModal/UpdateRoleModal";
import Spinner from "../elements/Spinner/Spinner";

const RolesConfiguration = () => {
  const [roleList, setRoleList] = useState([]);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);

  const isSearchBarOpen = useSelector((state) => state.auth.isSearchBarOpen);
  const [delayedMaxHeight, setDelayedMaxHeight] = useState(
    "calc(100vh - 238px)"
  );

  const dispatch = useDispatch();

  const currentUser = useMemo(() => {
    const loggedInUserDetail = localStorage.getItem("user_details");
    if (loggedInUserDetail) {
      const loggedInUser = CryptoJS.AES.decrypt(
        loggedInUserDetail,
        REACT_APP_CRYPTOJS_SECRETKEY,
        { iv: REACT_APP_CRYPTOJS_VECTOR }
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(loggedInUser);
    }
    return null;
  }, []);

  const [
    getRolesList,
    { isFetching: isRoleFetching, isLoading: iosRoleLoading },
  ] = useGetRolesListMutation();

  const fetchRoles = () => {
    getRolesList({
      query: currentUser?.UserName,
      payload: "",
    }).then((res) => {
      setRoleList(res?.data);
      dispatch(setAdminRoleList(res?.data));
    });
  };

  useEffect(() => {
    fetchRoles();
  }, [currentUser]);

  const openAddRoleModal = () => {
    setShowAddRoleModal(true);
  };

  const handleEdit = (id) => {
    setSelectedRoleId(id);
    setShowUpdateRoleModal(true);
  };

  const closeAddRoleModal = () => {
    setShowAddRoleModal(false);
    fetchRoles();
  };

  const closeUpdateRoleModal = () => {
    setShowUpdateRoleModal(false);
    fetchRoles();
  };

  const columns = useMemo(() => {
    const generatedColumns =
      roleList?.length > 0
        ? Object.keys(roleList[0]).map((key) => {
            if (key === "IsActive") {
              // Special handling for IsActive column
              return {
                accessorKey: key,
                header: key,
                size: 100,
                Cell: ({ cell }) => {
                  const isActive = cell.getValue();
                  return isActive ? (
                    <CheckIcon style={{ color: "green" }} />
                  ) : (
                    <CloseIcon style={{ color: "red" }} />
                  );
                },
              };
            }
            if (key === "IsAdmin") {
              // Special handling for IsActive column
              return {
                accessorKey: key,
                header: key,
                size: 100,
                Cell: ({ cell }) => {
                  const IsAdmin = cell.getValue();
                  return IsAdmin ? (
                    <CheckIcon style={{ color: "green" }} />
                  ) : (
                    <CloseIcon style={{ color: "red" }} />
                  );
                },
              };
            }
            return {
              accessorKey: key,
              header: key,
              size: 200,
              Cell: ({ cell }) => cell.getValue(),
            };
          })
        : [];

    generatedColumns?.length > 0 &&
      generatedColumns.push({
        accessorKey: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <IconButton
            onClick={() => handleEdit(row.original.RoleId)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        ),
      });

    return generatedColumns;
  }, [roleList]);

  useEffect(() => {
    if (isSearchBarOpen) {
      setDelayedMaxHeight("calc(100vh - 296px)");
    } else {
      const timer = setTimeout(() => {
        setDelayedMaxHeight("calc(100vh - 238px)");
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isSearchBarOpen]);

  const table = useMaterialReactTable({
    columns,
    data: roleList || [],
    enableDensityToggle: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    initialState: {
      density: "compact",
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: delayedMaxHeight,
        transition: "max-height 0.5s ease-in-out",
        zIndex: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #f5aa50",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        "& .Mui-TableHeadCell-Content-Labels": {
          padding: "0px",
        },
        "& .MuiBox-root": {
          padding: "0px",
        },
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
    },
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#e0e0e0",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
        <Button variant="contained" color="primary" onClick={openAddRoleModal}>
          Add New Role
        </Button>
      </Box>
    ),
  });

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="outer-dash-section">
            <div className="patient-sec">
              <div className="">
                <div className="row">
                  <div className="col-lg-12 left-column">
                    <div className="box-shadow-set p-2">
                      <div className="configure-head">
                        <div className="same-heading-icon">
                          <i className="fa fa-file-medical-alt fe-16" />
                          <h2>Role Configuration</h2>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="common-table">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddRoleModal
        open={showAddRoleModal}
        closeAddRoleModal={closeAddRoleModal}
        currentUser={currentUser}
      />

      <UpdateRoleModal
        open={showUpdateRoleModal}
        RoleId={selectedRoleId}
        closeUpdateRoleModal={closeUpdateRoleModal}
        currentUser={currentUser}
      />
      <Spinner isLoading={isRoleFetching || iosRoleLoading} />
    </AdminLayout>
  );
};

export default RolesConfiguration;
