/* eslint-disable */
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

import { ROLES } from "../../config/roles";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";

const ProtectedRoutes = ({ allowedRoles }) => {
  const location = useLocation();

  const encryptedUser = localStorage.getItem("user_details");

  const userDetails =
    encryptedUser &&
    CryptoJS.AES.decrypt(encryptedUser, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR, // Use 'iv' instead of REACT_APP_CRYPTOJS_VECTOR
    }).toString(CryptoJS.enc.Utf8);

  const userDetailsData = userDetails && JSON.parse(userDetails);

  const userType =
    userDetailsData?.IsAdmin === true ? ROLES?.ADMIN : ROLES?.USER;

  if (localStorage?.getItem("access_token") !== null) {
    const isAuthenticated = localStorage?.getItem("access_token");
    return isAuthenticated && userType === allowedRoles ? <Outlet /> : null; // or loading indicator, etc...
  }
  return <Navigate to={"/"} state={{ from: location }} replace />;
};

export default ProtectedRoutes;
