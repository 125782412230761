import React from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePostNewRoleMutation } from "../../services/apiService";
import { toast } from "react-toastify";
import { addRoleSchema } from "../../utils/validationSchema/validation";

const AddRoleModal = ({ open, closeAddRoleModal, currentUser }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      RoleName: "",
      IsActive: false,
      IsAdmin: false,
    },
    mode: "onBlur",
    resolver: yupResolver(addRoleSchema),
  });

  const [postNewRole, { isFetching, isLoading }] = usePostNewRoleMutation();

  const handleAddRole = (values) => {
    const params = {
      MenuIds: "",
      RoleId: 0,
      UserId: currentUser?.UserId,
      RoleName: values?.RoleName,
      IsActive: values?.IsActive,
      IsAdmin: values?.IsAdmin,
    };

    postNewRole({ query: "", payload: params }).then((res) => {
      toast.success("Role details added successfully");
      reset();
      closeAddRoleModal();
    });
  };

  return (
    <Modal open={open} onClose={closeAddRoleModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <div
          style={{
            display: "",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              marginBottom: "20px",
              color: "#212121",
            }}
          >
            Add Role
          </Typography>
          <Box>
            <form onSubmit={handleSubmit(handleAddRole)}>
              <div className="mb-3" style={{ marginBottom: "16px" }}>
                <Controller
                  name="RoleName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Role Name*"
                      fullWidth
                      variant="outlined"
                      error={!!errors.RoleName}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.FieldName ? errors.FieldName.message : ""}
                </FormHelperText>
              </div>
              <div
                className="row"
                style={{ gap: "10px", marginBottom: "20px" }}
              >
                <div className="mb-3">
                  <FormControlLabel
                    control={
                      <Controller
                        name="IsActive"
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    }
                    label="Is Active"
                  />
                </div>
                <div className="mb-3">
                  <FormControlLabel
                    control={
                      <Controller
                        name="IsAdmin"
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    }
                    label="Is Admin"
                  />
                </div>
              </div>
              <Box sx={{ gap: "10px", display: "flex" }}>
                <Button onClick={closeAddRoleModal} className="common-btn">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="common-btn"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default AddRoleModal;
