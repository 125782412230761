import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetPatientAppointmentsMutation } from "../../services/apiService";
import { setPatientAppointmentDetail } from "../../redux/slice/patientSlice";
import AdminLayout from "../Admin/AdminLayout";
import { IconButton, Typography, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Spinner from "../elements/Spinner/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import UserLayout from "../User/UserLayout";

const PatientAppointments = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [status, setStatus] = useState("All");
  const dispatch = useDispatch();
  const isSearchBarOpen = useSelector((state) => state.auth.isSearchBarOpen);
  const [delayedMaxHeight, setDelayedMaxHeight] = useState(
    "calc(100vh - 238px)"
  );

  const selectedPatient = localStorage.getItem("patient");
  const patient = selectedPatient && JSON.parse(selectedPatient);

  const encryptedUser = localStorage.getItem("user_details");

  const userDetails =
    encryptedUser &&
    CryptoJS.AES.decrypt(encryptedUser, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);

  const userDetailsData = userDetails && JSON.parse(userDetails);

  const Layout = userDetailsData?.IsAdmin === true ? AdminLayout : UserLayout;

  const [getPatientAppointments, { isLoading, isFetching }] =
    useGetPatientAppointmentsMutation();
  const patientAppointments = useSelector(
    (state) => state.patient.patientAppointMentsDetail
  );

  const fetchAppointments = () => {
    const payload = {
      Status: status !== "All" ? patient?.StatusId : "",
      PatientId: patient?.patientId,
      FromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
      ToDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
    };
    getPatientAppointments({ query: "", payload }).then((res) => {
      if (res?.data) {
        dispatch(setPatientAppointmentDetail(res?.data));
      }
    });
  };

  useEffect(() => {
    fetchAppointments();
  }, [patient?.patientId, fromDate, toDate, status]);

  const columns = useMemo(() => {
    if (!patientAppointments?.length) return [];

    return Object.keys(patientAppointments[0])
      .filter((key) => key !== "Date") // Remove "Date" column
      .map((key) => ({
        accessorKey: key,
        header: key,
        size: 200,
        Cell: ({ cell }) => {
          if (key === "DateRecorded") {
            return cell.getValue()
              ? moment(cell.getValue()).format("MM/DD/YYYY HH:mm")
              : "";
          }
          return cell.getValue();
        },
      }));
  }, [patientAppointments]);

  const table = useMaterialReactTable({
    columns,
    data: patientAppointments || [],
    enableDensityToggle: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    initialState: {
      density: "compact",
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: delayedMaxHeight,
        transition: "max-height 0.5s ease-in-out",
        zIndex: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #f5aa50",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        "& .Mui-TableHeadCell-Content-Labels": {
          padding: "0px",
        },
        "& .MuiBox-root": {
          padding: "0px",
        },
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
    },

    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#e0e0e0",
        },
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          selectsStart
          startDate={fromDate}
          endDate={toDate}
          showYearDropdown
          placeholderText="From"
          popperProps={{
            style: { zIndex: "9999999 !important" },
            strategy: "fixed",
          }}
          popperPlacement="bottom-end"
          className="date-picker"
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          selectsEnd
          startDate={fromDate}
          endDate={toDate}
          minDate={fromDate}
          showYearDropdown
          placeholderText="To"
          popperProps={{
            style: { zIndex: "9999999 !important" },
            strategy: "fixed",
          }}
          popperPlacement="bottom-end"
          className="date-picker"
        />
        {/* <FormControl>
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            onChange={handleStatusChange}
            label="Status"
            size="small"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </FormControl> */}
        <IconButton onClick={fetchAppointments}>
          <SearchIcon />
        </IconButton>
      </Box>
    ),
  });

  useEffect(() => {
    if (isSearchBarOpen) {
      setDelayedMaxHeight("calc(100vh - 296px)");
    } else {
      const timer = setTimeout(() => {
        setDelayedMaxHeight("calc(100vh - 238px)");
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isSearchBarOpen]);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="outer-dash-section">
            <div className="patient-sec">
              <div className="">
                <div className="row">
                  <div className="col-lg-12 left-column">
                    <div className="box-shadow-set p-2">
                      <div className="configure-head">
                        <div className="same-heading-icon">
                          <i className="fa fa-calendar-check fe-16 fe-16" />
                          <h2>Appointments</h2>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="common-table">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner isLoading={isLoading || isFetching} />
    </Layout>
  );
};

export default PatientAppointments;
