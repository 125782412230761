import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Modal,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

import {
  useGetFieldDetailsFromFieldIdMutation,
  usePostFieldDetailsMutation,
} from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { setAdminFieldDetail } from "../../redux/slice/adminSlice";
import { toast } from "react-toastify";
import { updateFieldSchema } from "../../utils/validationSchema/validation";
import { yupResolver } from "@hookform/resolvers/yup";
const UpdateFieldModal = ({
  FieldId,
  open,
  closeUpdateFieldModal,
  dropdownList,
  MenuId,
}) => {
  const dispatch = useDispatch();

  const defaultValues = {
    FieldName: "",
    Description: "",
    SortOrder: "",
    IsActive: false,
  };

  const {
    handleSubmit,
    watch,
    register,
    control,
    handleBlur,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(updateFieldSchema),
  });

  const [
    getFieldDetailsFromFieldId,
    { isFetching: isDetailsFetching, isLoading: isDetailsLoading },
  ] = useGetFieldDetailsFromFieldIdMutation();

  useEffect(() => {
    if (FieldId) {
      getFieldDetailsFromFieldId({ query: FieldId, payload: null }).then(
        (res) => {
          const details = res?.data?.Fielddetails;
          dispatch(setAdminFieldDetail(details));
          reset({
            ...details,
          });
        }
      );
    }
  }, [FieldId]);

  const [postFieldDetails, { isFetching, isLoading }] =
    usePostFieldDetailsMutation();

  const handleUpdateField = (values) => {
    const params = {
      FieldId: FieldId,
      Description: values.Description,
      FieldName: values.FieldName,
      SortOrder: values.SortOrder,
      IsActive: values.IsActive,
      UpdateType: 1,
      MenuId: MenuId,
    };

    postFieldDetails({ query: "", payload: params }).then((res) => {
      toast.success("Field details updated successfully");
      reset();
      closeUpdateFieldModal();
    });
  };

  return (
    <Modal open={open} onClose={closeUpdateFieldModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <div
          style={{
            display: "",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              marginBottom: "20px",
              color: "#212121",
            }}
          >
            Update Field
          </Typography>
          <Box>
            <form onSubmit={handleSubmit(handleUpdateField)}>
              <div className="row" style={{ gap: "10px" }}>
                <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                  <FormControl fullWidth>
                    <InputLabel>Field Name</InputLabel>
                    <Controller
                      name="FieldName"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} label="Field Name">
                          {dropdownList?.map((item) => (
                            <MenuItem
                              key={item.FieldValue}
                              value={item.FieldValue}
                            >
                              {item.FieldName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>
                      {errors.FieldName ? errors.FieldName.message : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-6 mb-3" style={{ marginBottom: "20px" }}>
                  <Controller
                    name="SortOrder"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        label="Sort Order"
                        fullWidth
                        variant="outlined"
                        error={!!errors.SortOrder}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errors.SortOrder ? errors.SortOrder.message : ""}
                  </FormHelperText>
                </div>
              </div>
              <div className="mb-3" style={{ marginBottom: "20px" }}>
                <Controller
                  name="Description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className="mb-3" style={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={
                    <Controller
                      name="IsActive"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Is Active"
                />
              </div>
              <Box sx={{ gap: "10px", display: "flex" }}>
                <Button onClick={closeUpdateFieldModal} className="common-btn">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="common-btn"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default UpdateFieldModal;
