import {
  configureStore,
  combineReducers,
  isRejectedWithValue,
  MiddlewareAPI,
  Middleware,
} from "@reduxjs/toolkit";

import { toast } from "react-toastify";

import { adminAPI, patientAPI, serverAPI } from "../services/apiService";
import authSlice from "./slice/authSlice";
import patientSlice from "./slice/patientSlice";
import adminSlice from "./slice/adminSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  patient: patientSlice,
  admin: adminSlice,
  [serverAPI.reducerPath]: serverAPI.reducer,
  [patientAPI.reducerPath]: serverAPI.reducer,
  [adminAPI.reducerPath]: adminAPI.reducer,
});

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action) || action?.meta?.rejectedWithValue === true) {
    let errorMsg =
      action.payload.error ||
      action.payload?.data?.message ||
      action.payload.data?.responseMessage ||
      action?.payload?.data?.statusText;
    if (errorMsg) {
      toast.error(errorMsg);
    }
  }
  return next(action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(serverAPI.middleware)
      .concat(patientAPI.middleware)
      .concat(adminAPI.middleware)

      .concat(rtkQueryErrorLogger),
});

export default store;
