import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import {
  REACT_APP_BACKEND_URL,
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../config/config";
import CryptoJS from "crypto-js";

const prepareHeaders = (headers, { getState }) => {
  const encryptedToken = localStorage.getItem("access_token");

  let token = null;
  try {
    if (encryptedToken) {
      token = CryptoJS.AES.decrypt(
        encryptedToken,
        REACT_APP_CRYPTOJS_SECRETKEY,
        {
          iv: REACT_APP_CRYPTOJS_VECTOR,
        }
      ).toString(CryptoJS.enc.Utf8);
    }
  } catch (error) {
    console.error("Error decrypting token:", error);
  }

  headers.set("Access-Control-Allow-Origin", "*");

  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }

  return headers;
};

const rawBaseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BACKEND_URL,
  prepareHeaders,
  // responseHandler: (response) => response.json(),
});

const retriedBaseQuery = retry(
  async (args, api, extraOptions) => {
    const result = await rawBaseQuery(args, api, extraOptions);

    // Customize retry logic as needed
    if (result.error && result.error.status !== 504) {
      retry.fail(result.error);
    }

    return result;
  },
  { maxRetries: 2 } // Set max retries here
);

export const serverAPI = createApi({
  reducerPath: "serverAPI",
  baseQuery: retriedBaseQuery,
  endpoints: (builder) => ({
    postTokenFetch: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/token",
        method: "POST",
        body: payload,
      }),
    }),

    postUserLogin: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetUserDetails",
        method: "POST",
        body: payload,
      }),
    }),
    postClientDetails: builder.mutation({
      query: ({ query, payload }) => ({
        url: "api/Users/InsertorUpdateClient",
        method: "POST",
        body: payload,
      }),
    }),
    getClientDetails: builder.query({
      query: () => ({ url: "/api/Users/GetClientdetails" }),
    }),
    getUserMenuList: builder.mutation({
      query: ({ query, payload }) => ({
        url: `api/Users/GetUserMenuList?UserName=${query}`,
      }),
    }),
  }),
});

export const {
  usePostTokenFetchMutation,
  usePostUserLoginMutation,
  useGetClientDetailsQuery,
  useGetUserMenuListMutation,
  usePostClientDetailsMutation,
} = serverAPI;
export const adminAPI = createApi({
  reducerPath: "adminAPI",
  baseQuery: retriedBaseQuery,
  endpoints: (builder) => ({
    getFieldDetailsFromMenuId: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/api/Users/GetFieldDetailsfromMenuId?menuId=${query}`,
      }),
    }),

    getFieldDetailsFromFieldId: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/api/Users/GetFieldDetailsfromFieldId?fieldId=${query}`,
      }),
    }),
    getFieldDetailsDropdownFromMenuId: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/api/Users/GetFieldDetailsdropdownMenuId?menuId=${query}`,
      }),
    }),
    getRoleRights: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/api/Users/GetRolesRights?roleId=${query}`,
      }),
    }),
    getUserByUserId: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/api/Users/GetuserbyUserid?userId=${query}`,
      }),
    }),
    getUserList: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/api/Users/GetUserList?username=${query}`,
        method: "POST",
        body: payload,
      }),
    }),
    getRolesList: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/api/Users/GetRolesList?username=${query}`,
        method: "POST",
        body: payload,
      }),
    }),
    getRoleDetailByRoleId: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/api/Users/GetRoledetails?roleId=${query}`,
      }),
    }),
    postFieldDetails: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Users/SaveorUpdateFieldDetails",
        method: "POST",
        body: payload,
      }),
    }),
    postNewRole: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Users/SaveorUpdateRoleDetails",
        method: "POST",
        body: payload,
      }),
    }),
    postRoleRights: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Users/UpdateRoleRights",
        method: "POST",
        body: payload,
      }),
    }),
    postAddNewUser: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Users/AddnewUser",
        method: "POST",
        body: payload,
      }),
    }),
    postUpdateUser: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Users/UpdateUsers",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetFieldDetailsFromMenuIdMutation,
  useGetFieldDetailsFromFieldIdMutation,
  useGetFieldDetailsDropdownFromMenuIdMutation,
  useGetRoleRightsMutation,
  useGetUserByUserIdMutation,
  useGetUserListMutation,
  useGetRoleDetailByRoleIdMutation,
  usePostFieldDetailsMutation,
  usePostNewRoleMutation,
  usePostRoleRightsMutation,
  useGetRolesListMutation,
  usePostAddNewUserMutation,
  usePostUpdateUserMutation,
} = adminAPI;

export const patientAPI = createApi({
  reducerPath: "patientAPI",
  baseQuery: retriedBaseQuery,
  endpoints: (builder) => ({
    getDynamicHeaderFromMenuId: builder.query({
      query: (menuId) => ({
        url: `/api/Users/GetDynamicHeaderByMenuId?menuId=${menuId}`,
      }),
    }),
    getLatestTenPatientsForDropdown: builder.query({
      query: (userId) => ({
        url: `/api/Archival/GetLastTenPatientsList?userId=${userId}`,
      }),
    }),
    getPatientDetails: builder.query({
      query: (patientId) => ({
        url: `/api/Archival/GetPatientDetails?patientId=${patientId}`,
      }),
    }),
    getDocumentsCount: builder.query({
      query: (patientId) => ({
        url: `/api/Archival/GetTabCounts?patientId=${patientId}`,
      }),
    }),
    getPatientDemographicsonSearch: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetAllPatientsDemographicsOnSearch",
        method: "POST",
        body: payload,
      }),
    }),
    getSinglePatientDemographic: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetDemographics",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientAppointments: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetAppoinments",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientLabResults: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetLabResults",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientEncounters: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetEncounters",
        method: "POST",
        body: payload,
      }),
    }),
    getCareTeam: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetCareTeam",
        method: "POST",
        body: payload,
      }),
    }),
    getEncounterInsurance: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetEncounterInsurance",
        method: "POST",
        body: payload,
      }),
    }),
    getSurgicalHistory: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetSurgicalHistory",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientPharmacy: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetPatientPharmacy",
        method: "POST",
        body: payload,
      }),
    }),
    getOrder: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetOrder",
        method: "POST",
        body: payload,
      }),
    }),
    getEncounterProvider: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetEncounterProvider",
        method: "POST",
        body: payload,
      }),
    }),
    getDiagnosis: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetDiagnosis",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientProblems: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetProblems",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientAllergies: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetAlergyDetails",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientMedications: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetMedicationhistory",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientImmunization: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetImmunizationDetails",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientPastMedicalHistory: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetPastMedicalHistory",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientFamilyHistory: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetFamilyHistory",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientSocialHistory: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetSocialHistory",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientVitalDetails: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetVitalDetails",
        method: "POST",
        body: payload,
      }),
    }),
    getPatientDocumentDetails: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/api/Archival/GetDocuments",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetCareTeamMutation,
  useGetEncounterInsuranceMutation,
  useGetSurgicalHistoryMutation,
  useGetPatientPharmacyMutation,
  useGetOrderMutation,
  useGetEncounterProviderMutation,
  useGetDiagnosisMutation,
  useGetDynamicHeaderFromMenuIdQuery,
  useLazyGetDynamicHeaderFromMenuIdQuery,
  useGetLatestTenPatientsForDropdownQuery,
  useGetPatientDetailsQuery,
  useGetDocumentsCountQuery,
  useGetPatientDemographicsonSearchMutation,
  useGetSinglePatientDemographicMutation,
  useGetPatientAppointmentsMutation,
  useGetPatientLabResultsMutation,
  useGetPatientEncountersMutation,
  useGetPatientProblemsMutation,
  useGetPatientAllergiesMutation,
  useGetPatientMedicationsMutation,
  useGetPatientImmunizationMutation,
  useGetPatientPastMedicalHistoryMutation,
  useGetPatientFamilyHistoryMutation,
  useGetPatientSocialHistoryMutation,
  useGetPatientVitalDetailsMutation,
  useGetPatientDocumentDetailsMutation,
} = patientAPI;
