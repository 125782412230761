import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AdminLayout from "../Admin/AdminLayout";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import {
  usePostRoleRightsMutation,
  useGetRolesListMutation,
  useGetRoleRightsMutation,
} from "../../services/apiService";
import { colourStyles } from "../../utils/customStyle/style";
import { setAdminRoleList } from "../../redux/slice/adminSlice";

// Utility functions
function not(a, b) {
  return a?.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a?.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const MenuConfiguration = () => {
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleRightsList, setRoleRightsList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const dispatch = useDispatch();

  const currentUser = useMemo(() => {
    const loggedInUserDetail = localStorage.getItem("user_details");
    if (loggedInUserDetail) {
      const loggedInUser = CryptoJS.AES.decrypt(
        loggedInUserDetail,
        REACT_APP_CRYPTOJS_SECRETKEY,
        { iv: REACT_APP_CRYPTOJS_VECTOR }
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(loggedInUser);
    }
    return null;
  }, []);

  const [postRoleRights] = usePostRoleRightsMutation();
  const [getRolesList] = useGetRolesListMutation();
  const [getRoleRights] = useGetRoleRightsMutation();

  useEffect(() => {
    if (currentUser?.UserName) {
      fetchRoles();
    }
  }, [currentUser?.UserName]);

  const fetchRoles = () => {
    getRolesList({ query: currentUser?.UserName, payload: "" }).then((res) => {
      setRoleList(res?.data);
      dispatch(setAdminRoleList(res?.data));
    });
  };

  useEffect(() => {
    if (selectedRole) {
      getRoleRights({ query: selectedRole?.RoleId, payload: null }).then(
        (res) => {
          setRoleRightsList(res?.data);
          // Initialize left and right based on IsGrantacess
          setLeft(res?.data?.filter((item) => !item.IsGrantacess));
          setRight(res?.data?.filter((item) => item.IsGrantacess));
        }
      );
    }
  }, [selectedRole]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(
      left?.filter((item) => checked.includes(item.MenuId))
    );
    setRight(newRight);
    setLeft(
      not(
        left,
        left?.filter((item) => checked.includes(item.MenuId))
      )
    );
    setChecked([]);
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(
      right?.filter((item) => checked.includes(item.MenuId))
    );
    setLeft(newLeft);
    setRight(
      not(
        right,
        right?.filter((item) => checked.includes(item.MenuId))
      )
    );
    setChecked([]);
  };

  const saveRoleRights = () => {
    // Update IsGrantacess based on whether the item is in the right list
    // const updatedRights = roleRightsList.map((item) => ({
    //   MenuId: item.MenuId,
    //   IsGrantacess: right.some((rightItem) => rightItem.MenuId === item.MenuId),
    // }));
    const MenuIds = right.map((item) => item.MenuId).join(",");

    const params = {
      RoleId: selectedRole?.RoleId,
      UserId: currentUser?.UserId,
      RoleName: selectedRole?.Role,
      MenuIds: MenuIds,
    };

    postRoleRights({ query: "", payload: params }).then((res) => {
      toast.success(res?.data?.Message);
    });
  };

  const customList = (title, items) => (
    <Card sx={{}}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items.map((item) => item.MenuId))}
            checked={
              numberOfChecked(items.map((item) => item.MenuId)) ===
                items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items.map((item) => item.MenuId)) !==
                items.length &&
              numberOfChecked(items.map((item) => item.MenuId)) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items.map((item) => item.MenuId))}/${
          items.length
        } selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((item) => {
          const labelId = `transfer-list-item-${item.MenuId}-label`;

          return (
            <ListItemButton
              key={item.MenuId}
              role="listitem"
              onClick={handleToggle(item.MenuId)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(item.MenuId)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.MenuName} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="outer-dash-section">
            <div className="patient-sec">
              <div className="container">
                <div className="">
                  <div className="col-md-12 left-column">
                    <div className="box-shadow-set p-2">
                      <div className="col-md-12">
                        <div className="configure-head">
                          <div className="same-heading-icon">
                            <h2>Menu Configuration</h2>
                          </div>
                        </div>
                        <div className="configure-head-detail menu-flex-sec">
                          <div className="col-md-6 mb-2">
                            <Select
                              placeholder={"Select Role"}
                              styles={colourStyles}
                              onChange={(e) => setSelectedRole(e)}
                              value={selectedRole}
                              options={roleList}
                              getOptionLabel={(role) => role.Role}
                              getOptionValue={(role) => role.RoleId}
                              className="select-field"
                            />
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={saveRoleRights}
                            disabled={!selectedRole}
                            className="common-btn"
                          >
                            Save All
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-12 p-0">
                        <div
                          className="common-table"
                          style={{ paddingTop: 20 }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="start"
                            alignItems="center"
                            sx={{ width: "100%", marginLeft: "0" }}
                          >
                            <Grid
                              item
                              className="grid-box"
                              sx={{ width: "40%" }}
                            >
                              {customList("Available Menus", left)}
                            </Grid>
                            <Grid
                              item
                              className="padding-set"
                              sx={{ width: "20%" }}
                            >
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                              >
                                <Button
                                  sx={{ my: 0.5 }}
                                  variant="outlined"
                                  size="small"
                                  onClick={handleCheckedRight}
                                  disabled={checked.length === 0}
                                  className="margin-set"
                                >
                                  &gt;
                                </Button>
                                <Button
                                  sx={{ my: 0.5 }}
                                  variant="outlined"
                                  size="small"
                                  onClick={handleCheckedLeft}
                                  disabled={checked.length === 0}
                                  className="margin-set"
                                >
                                  &lt;
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className="grid-box"
                              sx={{ width: "40%" }}
                            >
                              {customList("Granted Menus", right)}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default MenuConfiguration;
