import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const Spinner = ({ isLoading, dontClose }) => {
  return (
    isLoading && (
      <Box
        className="position-fixed"
        sx={{
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(4px)",
          zIndex: 99999,
          position: "fixed",
        }}
      >
        <Box
          id="spinner"
          sx={{
            position: "relative",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress size={60} sx={{ color: "#293089" }} />
          {dontClose && (
            <Typography
              variant="body2"
              sx={{ color: "error.main", mt: 2, textAlign: "center" }}
            >
              <strong>
                Please do not refresh or close this window. Your action may
                disrupt the ongoing process...
              </strong>
            </Typography>
          )}
        </Box>
      </Box>
    )
  );
};

export default Spinner;
