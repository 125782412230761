import React from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePostAddNewUserMutation } from "../../services/apiService";
import { toast } from "react-toastify";
import { addUserSchema } from "../../utils/validationSchema/validation";

const AddUserModal = ({ open, closeAddUserModal, roleList }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      FirstName: "",
      LastName: "",
      Title: "",
      Department: "",
      Email: "",
      Phone: "",
      UserName: "",
      Password: "",
      Role: null, // Set as null for the Select component
      IsActive: false,
    },
    mode: "onBlur",
    resolver: yupResolver(addUserSchema),
  });

  const [postAddNewUser, { isFetching, isLoading }] =
    usePostAddNewUserMutation();

  const handleAddUser = (values) => {
    const params = {
      Id: 0,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Title: values.Title,
      Department: values.Department,
      Email: values.Email,
      Phone: values.Phone,
      UserName: values.UserName,
      Password: values.Password,
      RoleId: values.Role?.RoleId, // Use the selected RoleId
      IsActive: values.IsActive,
    };

    postAddNewUser({ query: "", payload: params }).then((res) => {
      toast.success("User details added successfully");
      reset();
      closeAddUserModal();
    });
  };

  return (
    <Modal open={open} onClose={closeAddUserModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add User
        </Typography>
        <Box>
          <form onSubmit={handleSubmit(handleAddUser)}>
            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <Controller
                  name="FirstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name*"
                      fullWidth
                      variant="outlined"
                      error={!!errors.FirstName}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.FirstName ? errors.FirstName.message : ""}
                </FormHelperText>
              </div>
              <div className="col-md-6 mb-3">
                <Controller
                  name="LastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name*"
                      fullWidth
                      variant="outlined"
                      error={!!errors.LastName}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.LastName ? errors.LastName.message : ""}
                </FormHelperText>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <Controller
                  name="Title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Title"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className="col-md-6 mb-3">
                <Controller
                  name="Department"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Department"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <Controller
                  name="Email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email*"
                      fullWidth
                      variant="outlined"
                      type="email"
                      error={!!errors.Email}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.Email ? errors.Email.message : ""}
                </FormHelperText>
              </div>
              <div className="col-md-6 mb-3">
                <Controller
                  name="Phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <Controller
                  name="UserName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Username*"
                      fullWidth
                      variant="outlined"
                      error={!!errors.UserName}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.UserName ? errors.UserName.message : ""}
                </FormHelperText>
              </div>
              <div className="col-md-6 mb-3">
                <Controller
                  name="Password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password*"
                      fullWidth
                      variant="outlined"
                      type="password"
                      error={!!errors.Password}
                    />
                  )}
                />
                <FormHelperText>
                  {errors.Password ? errors.Password.message : ""}
                </FormHelperText>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 mb-3">
                <Controller
                  name="Role"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select Role"
                      options={roleList}
                      getOptionLabel={(role) => role.Role}
                      getOptionValue={(role) => role.RoleId}
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption)
                      }
                      value={field.value}
                      isClearable
                      error={!!errors.Role}
                      required
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-3">
              <FormControlLabel
                control={
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                }
                label="Is Active"
              />
            </div>

            <Box>
              <Button onClick={closeAddUserModal}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUserModal;
