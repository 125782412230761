import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserMenuListMutation } from "../../services/apiService";
import CryptoJS from "crypto-js";
import Spinner from "../../components/elements/Spinner/Spinner";
import {
  setTableHeader,
  setUserMenuList,
} from "../../redux/slice/patientSlice";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import {
  REACT_APP_BACKEND_URL,
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import "../Admin/AdminPatientList.css";
import UserLayout from "../../components/User/UserLayout";

const UserPatientList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState([]);
  const [menu, setMenu] = useState(null);
  const isSearchBarOpen = useSelector((state) => state.auth.isSearchBarOpen);
  const [delayedMaxHeight, setDelayedMaxHeight] = useState(
    "calc(100vh - 238px)"
  );
  const patientList = useSelector((state) => state.patient.PatientList);

  const encryptedUser = localStorage.getItem("user_details");

  const userDetails =
    encryptedUser &&
    CryptoJS.AES.decrypt(encryptedUser, REACT_APP_CRYPTOJS_SECRETKEY, {
      iv: REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);

  const userDetailsData = userDetails && JSON.parse(userDetails);

  const encryptedToken = localStorage.getItem("access_token");

  useEffect(() => {
    localStorage.removeItem("patient");
  }, []);

  let token = null;
  try {
    if (encryptedToken) {
      token = CryptoJS.AES.decrypt(
        encryptedToken,
        REACT_APP_CRYPTOJS_SECRETKEY,
        { iv: REACT_APP_CRYPTOJS_VECTOR }
      ).toString(CryptoJS.enc.Utf8);
    }
  } catch (error) {
    console.error("Error decrypting token:", error);
  }

  useEffect(() => {
    if (isSearchBarOpen) {
      setDelayedMaxHeight("calc(100vh - 296px)");
    } else {
      const timer = setTimeout(() => {
        setDelayedMaxHeight("calc(100vh - 238px)");
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isSearchBarOpen]);

  const [
    getUserMenuList,
    { isFetching: isMenuListFetching, isLoading: isMenuListLoading },
  ] = useGetUserMenuListMutation();

  useEffect(() => {
    getUserMenuList({ query: userDetailsData?.UserName, payload: "" }).then(
      (res) => {
        dispatch(setUserMenuList(res?.data));
        const selectedMenu = res?.data?.find(
          (menu) => menu.MenuName === "Patient List"
        );
        setMenu(selectedMenu);
      }
    );
  }, [userDetailsData?.UserName]);

  useEffect(() => {
    if (menu?.MenuId) {
      fetch(
        `${REACT_APP_BACKEND_URL}/api/Users/GetDynamicHeaderByMenuId?menuId=${menu?.MenuId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          dispatch(setTableHeader(data));
        })
        .catch((error) => console.error("Error fetching table header:", error));
    }
  }, [menu, token, dispatch]);

  const tableHeader = useSelector((state) => state.patient.TableHeader);

  const columns = useMemo(() => {
    if (!tableHeader) return [];

    const desiredOrder = [
      "Name",
      "SSN",
      "MRN",
      "PatientChartNumber", // Will be renamed to "Visit Number"
      "DOB",
      "Sex",
    ];

    const headers = tableHeader.split("|");

    return desiredOrder
      .filter((header) => headers.includes(header))
      .map((header) => {
        const isBold = ["Name", "SSN", "MRN"].includes(header);
        return {
          accessorKey: header,
          header: header === "PatientChartNumber" ? "Visit Number" : header,
          Cell: ({ row, cell }) => (
            <span
              onClick={() => {
                handleRowClick(row.original);
              }} // Apply click handler to the cell
              style={{
                fontWeight: isBold ? "bold" : "normal",
                cursor: isBold ? "pointer" : "default",
              }}
            >
              {cell.getValue()}
            </span>
          ),
          size: 250,
        };
      });
  }, [tableHeader]);

  useEffect(() => {
    if (patientList?.length > 0) {
      setPatientData(patientList);
    }
  }, [patientList]);

  const handleRowClick = (row) => {
    localStorage.setItem("patient", JSON.stringify(row));
    navigate(`/user/patient-list/patient/demographics`, { replace: true });
  };

  const table = useMaterialReactTable({
    columns,
    data: patientData || [],
    enableDensityToggle: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    initialState: {
      density: "compact",
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50, 100, 500],
      showFirstButton: false,
      showLastButton: false,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: delayedMaxHeight,
        transition: "max-height 0.5s ease-in-out",
        zIndex: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottom: "3px solid #f5aa50",
        borderRight: "solid #e0e0e0",
        alignItems: "center",
        "& .Mui-TableHeadCell-Content-Labels": {
          padding: "0px",
        },
        "& .MuiBox-root": {
          padding: "0px",
        },
        backgroundColor: "white",
        borderTop: "solid #e0e0e0",
      },
      customizeToolbar: {
        height: "50px",
      },
    },

    muiTableBodyProps: {
      sx: {
        "&:nth-of-type(odd)": { backgroundColor: "#e0e0e0" },
        "&:nth-of-type(even)": { backgroundColor: "#f5f5f5" },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    components: {
      LoadingOverlay: Spinner,
    },
  });

  return (
    <UserLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="outer-dash-section">
            <div className="patient-sec">
              <div className="">
                <div className="row">
                  <div className="col-lg-12 left-column">
                    <div className="box-shadow-set p-2">
                      <div className="configure-head">
                        <div className="same-heading-icon">
                          <i className="fa fa-file-medical-alt fe-16" />
                          <h2>Search</h2>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="common-table">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default UserPatientList;
