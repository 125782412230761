import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  useGetRoleDetailByRoleIdMutation,
  usePostNewRoleMutation,
} from "../../services/apiService";
import { useDispatch } from "react-redux";
import { setAdminRoleDetail } from "../../redux/slice/adminSlice";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateRoleSchema } from "../../utils/validationSchema/validation";
const UpdateRoleModal = ({
  RoleId,
  open,
  closeUpdateRoleModal,
  currentUser,
}) => {
  const dispatch = useDispatch();

  const defaultValues = {
    RoleName: "",
    IsActive: false,
    IsAdmin: false,
  };

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(updateRoleSchema),
  });

  const [
    getRoleDetailByRoleId,
    { isFetching: isDetailsFetching, isLoading: isDetailsLoading },
  ] = useGetRoleDetailByRoleIdMutation();

  useEffect(() => {
    if (RoleId) {
      getRoleDetailByRoleId({ query: RoleId, payload: null }).then((res) => {
        const details = res?.data;
        dispatch(setAdminRoleDetail(details));
        reset({
          ...details,
          RoleName: details?.Role,
        });
      });
    }
  }, [RoleId]);

  const [postNewRole, { isFetching, isLoading }] = usePostNewRoleMutation();

  const handleUpdateRole = (values) => {
    const params = {
      MenuIds: "",
      RoleId: RoleId,
      UserId: currentUser?.UserId,
      RoleName: values?.RoleName,
      IsActive: values?.IsActive,
      IsAdmin: values?.IsAdmin,
    };

    postNewRole({ query: "", payload: params }).then((res) => {
      toast.success("Role details updated successfully");
      reset();
      closeUpdateRoleModal();
    });
  };

  return (
    <Modal open={open} onClose={closeUpdateRoleModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              marginBottom: "20px",
              color: "#212121",
            }}
          >
            Update Role
          </Typography>
        </div>
        <Box mt={2}>
          <form onSubmit={handleSubmit(handleUpdateRole)}>
            <div className="mb-3" style={{ marginBottom: "16px" }}>
              <Controller
                name="RoleName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Role Name*"
                    fullWidth
                    variant="outlined"
                    error={!!errors.RoleName}
                  />
                )}
              />
              <FormHelperText>
                {errors.FieldName ? errors.FieldName.message : ""}
              </FormHelperText>
            </div>
            <div className="mb-3">
              <FormControlLabel
                control={
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                }
                label="Is Active"
              />
              <div className="mb-3">
                <FormControlLabel
                  control={
                    <Controller
                      name="IsAdmin"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} />
                      )}
                    />
                  }
                  label="Is Admin"
                />
              </div>
            </div>
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
              sx={{ gap: "10px", display: "flex" }}
            >
              <Button onClick={closeUpdateRoleModal} className="common-btn">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="common-btn"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateRoleModal;
